import { useState } from 'react'
import { getResultCars, updateInfiniteScrollCar, resultResultCars, loadingSearchCombined,
    addRemoveComparar } from 'services/actions/carActions'
import { getDataInitialBrand, getUpdateVisibleBrand } from'services/actions/marcaActions'
import { getSearchByParamsUrl, saveSearchNormal, getDataInitialSearchBrandModel } from 'services/actions/searchActions'
import { getListSupplier } from 'services/actions/providerAction'
import { getListInitialServices } from 'services/actions/serviceActions'


import { useConfig } from "hooks/MetaHead/useConfig"
import { useAdvertising } from 'hooks/useAdvertising'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from "react-router-dom"

export const useResultMain = () => {
    
	const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const [ menuSort, setMenuSort ] = useState(false)
	//const { getLoadProvider } = useFeatured("", false)
	const [ menuFilter, setMenuFilter ] = useState(false)
    const [ descriptionMeta, setDescriptionMeta ] = useState({})
    const [ isOpen, setIsOpen ] = useState(false)

	const openModal = () => setIsOpen(isOpen => !isOpen)

    const { generateMetaTagMain } = useConfig()
    const { actionAdvertising, advertisingResult, getAdvertisingInitial } = useAdvertising()

    const { cars, marcas, search, providers, services } = useSelector((state) => {
		return {
			cars: state.cars,
			marcas: state.marcas,
			search: state.search,
			providers: state.providers,
			services: state.services
		}
	})

	const { listBrands  } = marcas
	//const { featureds_cars } = cars
	const { initialDataNormal, itemOfSearch, selectedTab, titleHeader, hiddenOrigin  } = search
	const { listResultCar, listCompareCars, countResult, 
		isLoadingResult, selectedCarQuote, hasMoreCar  }= cars
	const { listSuppliers } = providers
	const { categoryProviders } = services

    const closeOpenMenuSort = () => setMenuSort(!menuSort)
	const closeOpenMenuFilter = () => setMenuFilter(!menuFilter)

    /**Función para el infinite loader */
	const fetchMoreData = () => {
		setTimeout(() => {
            dispatch(updateInfiniteScrollCar())
          }, 1500);
	}

    const searchParamsByUrl = async (paramsUrl) => {
        dispatch(loadingSearchCombined(true))

		let params = []

		if(location.pathname==="/s/autos/nuevos/" || location.pathname==="/s/autos/nuevos"){
            params = [ "nuevos"]
        }else if(location.pathname==="/s/autos/usados/" || location.pathname==="/s/autos/usados"){
            params = [ "usados"]
        }else{
			const splitParams = paramsUrl[0]
			params = splitParams.split('/')
			params = params.filter(item => item!=="")
		}

		const infoMeta = generateMetaTagMain(location?.pathname, params)
	
		setDescriptionMeta(infoMeta)

		await getAdvertisingInitial()

		if(listBrands.length===0){
			await dispatch(getDataInitialBrand());
		}

		if(categoryProviders.length===0){
			await dispatch(getListInitialServices())	
		}
		if(listSuppliers.length===0){
			await dispatch(getListSupplier(false))	
		}
		
		if(params.includes("nuevos") && params.includes("usados")){
			dispatch(getUpdateVisibleBrand('other'))
		}else if(params.includes("nuevos")){
			dispatch(getUpdateVisibleBrand('car-new'))
		}else if(params.includes("usados")){
			dispatch(getUpdateVisibleBrand('car-used'))
		}

		dispatch(getDataInitialSearchBrandModel())

		if(location?.pathname==="/autos/usados/diesel-4x4"){
            params = [ "usados", "diesel", "4x4"]
        }else if(location.pathname==="/autos/usados/familiares"){
            params = [ "usados", "suv"]
        }else if(location.pathname==="/autos/usados/diesel-petroleros"){
            params = [ "usados", "diesel"]
        }else if(location.pathname==="/autos/usados/diesel-petroleros/automaticos"){
			params = [ "usados", "diesel", "automaticos"]
		}else if(location.pathname==="/autos/usados/deportivos"){
			params = [ "usados", "coupe"]
		} if(location.pathname==="/autos/nuevos/petroleros-diesel"){
            params = [ "nuevos", "diesel"]
        }else if(location.pathname==="/autos/nuevos/familiares"){
            params = [ "nuevos", "suv"]
        }else if(location.pathname==="/autos/nuevos/brilliance/t32-doble-cabina"){
            params = [ "nuevos", "brilliance", "t32", "camioneta", "pick up", "cabina simple", "doble cabina"]
        }else if(location.pathname==="/autos/nuevos/changan/hunter-4x4"){
            params = [ "nuevos", "changan", "hunter", "4x4"]
        }else if(location.pathname==="/autos/nuevos/changan/cs55-plus"){
            params = [ "nuevos", "changan", "cs55"]
        }else if(location.pathname==="/autos/nuevos/haval/all-new-haval-h6"){
            params = [ "nuevos", "haval", "h6"]
        }else if(location.pathname==="/autos/nuevos/haval/all-new-h6-4x4"){
            params = [ "nuevos", "haval", "h6", "4x4"]
        }else if(location.pathname==="/autos/nuevos/haval/jolion-deluxe"){
            params = [ "nuevos", "haval", "jolion"]
        }else if(location.pathname==="/autos/nuevos/honda/crv-4x4"){
            params = [ "nuevos", "honda", "cr-v", "4x4"]
        }else if(location.pathname==="/autos/nuevos/jetour/x70-plus"){
            params = [ "nuevos", "jetour", "x70"]
        }else if(location.pathname==="/autos/nuevos/mahindra/xuv500-4x4"){
            params = [ "nuevos", "mahindra", "xuv500", "4x4"]
        }else if(location.pathname==="/autos/nuevos/mahindra/pick-up-4x4"){
            params = [ "nuevos", "mahindra", "pik up", "4x4"]
        }else if(location.pathname==="/autos/nuevos/maxus/t60-4x4"){
            params = [ "nuevos", "maxus", "t60", "4x4"]
        }else if(location.pathname==="/autos/nuevos/seat/terraco-4x4"){
            params = [ "nuevos", "seat", "tarraco", "4x4"]
        }
		
		const response = dispatch(getSearchByParamsUrl({ params }))

		if(response.status){
			//dispatch(addSelectedDefaultResultByUrl(response.data, response.listBrandsSelectedEdit))
			let result =  dispatch(saveSearchNormal(response.data, false, location?.pathname))
		
			if(result.status){
				await dispatch(getResultCars())
			}
		} else {
			dispatch(resultResultCars())
		}	
		window.scrollTo(0, 0)
	}

    const getFileCar = async (key) => {
		//await dispatch(getDetailsCar(key, 'normal'))
		history.push(`/autos/${key}`, {prevPath: location?.pathname, name: "Búsqueda" })
	}

    const goToConcesionaire = (slug) => {
		history.push(`/concesionario/${slug}`, {prevPath: location?.pathname, name: "Búsqueda" })
	}

    const addCompareCar = (event, id) => {
		const response = dispatch(addRemoveComparar(event.target.checked, id))
		if(response.error){
			event.target.checked=false
			openModal(true)
		}
	}


    const state = {
        listBrands,
        initialDataNormal, 
        itemOfSearch, 
        selectedTab, 
        titleHeader,
        listResultCar, 
        listCompareCars, 
        countResult,
        isLoadingResult, 
        selectedCarQuote, 
        hasMoreCar,
        listSuppliers,
        categoryProviders,
        descriptionMeta,
        advertisingResult,
        menuSort,
		isOpen,
		menuFilter,
		hiddenOrigin
    }

    const actions = {
        closeOpenMenuSort,
        closeOpenMenuFilter,
        fetchMoreData,
        searchParamsByUrl,
        actionAdvertising,
        getFileCar,
        goToConcesionaire,
        addCompareCar,
        setMenuSort,
		setMenuFilter
    }

	return {
		state,
        actions
	}
}