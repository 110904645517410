import { useEffect } from "react"
/**import icon_1 from "assets/images/concourse/1.svg"
import icon_2 from "assets/images/concourse/2.svg"
import icon_3 from "assets/images/concourse/3.svg"**/
import uphload_photo from "assets/images/concourse/uphload_photo.svg"
import take_photo from "assets/images/concourse/take_photo.svg"
import styles from 'styles/concourse.module.css'

const mobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (mobile.Android() || mobile.BlackBerry() || mobile.iOS() || mobile.Opera() || mobile.Windows());
  }
};

const FormPhoto = ({ handleTakePhoto, handleChangeTakePhoto, btnTakePhotoDsk, 
	btnTakePhotoMobile, title, info_1, info_3 }) => {

	useEffect(() => {
		const typeDevice = mobile.any()
		
		if(btnTakePhotoDsk.current!==null && btnTakePhotoMobile.current!==null){
			if(typeDevice===null){
				btnTakePhotoDsk.current.classList.remove('d-none');
				btnTakePhotoMobile.current.classList.add('d-none');
			}else{
				btnTakePhotoMobile.current.classList.remove('d-none');
				btnTakePhotoDsk.current.classList.add('d-none');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className={styles["list-item"]}>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>1</p>
					</div>
				    <p className={styles["item-description"]}>{info_1}</p>
				</div>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>2</p>
					</div>
				    <p className={styles["item-description"]}>Completa el formulario con tus datos.</p>
				</div>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>3</p>
					</div>
				    <p className={styles["item-description"]}>{info_3}</p>
				</div>
			</div>
			<div className={styles["options"]}>
				<div className={styles["options-btn"]}>
					<input 
						type="file" 
						accept="image/*" 
						name="uphold-photo" 
						id="uphold-photo" 
						className={styles["file-photo"]}
						onChange={(event) => handleChangeTakePhoto(event)}
					/>
					<div className={styles["options-btn__image"]}>
						<img src={uphload_photo} alt="Cargar Foto" />
					</div>
					<p className={styles["options-btn__text"]}>Cargar foto</p>
				</div>
				<div className={`d-none ${styles["options-btn"]}`} ref={btnTakePhotoMobile}>
					<input 
						type="file" 
						accept="image/*" 
						capture="camera" 
						name="photo" 
						id="photo" 
						className={styles["file-photo"]}
						onChange={(event) => handleChangeTakePhoto(event)}
					/>
					<div className={`${styles["options-btn__image"]} ${styles["options-btn__image--take"]}`}>
						<img src={take_photo} alt="Tomar Foto" />
					</div>
					<p className={`${styles["options-btn__text"]} ${styles["options-btn__text--take"]}`}>Tomar foto</p>
				</div>
				<div 
					className={`d-none ${styles["options-btn"]}`} 
					ref={btnTakePhotoDsk}
					onClick={(event) => handleTakePhoto(event)}
				>
					<div className={`${styles["options-btn__image"]} ${styles["options-btn__image--take"]}`}>
						<img src={take_photo} alt="Tomar Foto" />
					</div>
					<p className={`${styles["options-btn__text"]} ${styles["options-btn__text--take"]}`}>Tomar foto</p>
				</div>
			</div>
			
		</>
		
	)
}

export default FormPhoto