import { INITIAL_CONFIG,  PAGE_SELECTED_LAST } from 'services/types/configType';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getConfigInitial = (value) => (dispatch, getState) => {
	const payload = value;

    dispatch(requestSuccess(INITIAL_CONFIG, payload));
};



export const savePageCurrent = (value) => (dispatch, getState) => {
	const payload = value;

    dispatch(requestSuccess(PAGE_SELECTED_LAST, payload));
};