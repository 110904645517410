import API from 'services/context/api'
import { 
	INFO_INITIAL_CONCOURSE,
	NEXT_FORM_PREV_CONCOURSE,
	SAVE_FORM_CONCOURSE,
	SAVE_FORM_DATA_GLOBAL_CONCOURSE
} from 'services/types/concourseType'

import endPoints from 'services/routes/';
import { generateNameImage } from 'utils/toolDate'
import { TOKEN_SITE_WEB } from "data/variableKey";

export const requestSuccess = (type, payload) => {
    return { type, payload }
}

export const nextPrevForm = (key) => (dispatch, getState) => {
	let form = ["form_initial", "form_capture", "form_photo", "form_contact", "form_end"]
    const payload = { nextForm: form[key]} 
    dispatch(requestSuccess(NEXT_FORM_PREV_CONCOURSE, payload))
}

export const resetInfoInitial = (nameForm) => async (dispatch, getState) => {
	const dataTypeConcourse = {
		rut: "",
		name: "",
		surname: "",
		phone: "",
		email: "",
		photo: "",
		pictures: null,
		typePictures: ""
	}

	const payload = {
		nextFormNameConcourse: nameForm,
		dataTypeConcourse
	}

	dispatch(requestSuccess(INFO_INITIAL_CONCOURSE, payload))
}



export const saveFormDataConcourseCar = (data, success) => async (dispatch, getState) => {
	
	try{
		const { dataTypeConcourse } = getState().concourses

		let titleError = "¡Ups!"
		let subtitleError = "Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente"
		let btnTitleError = "Reintentar"

		let titleSuccess = "¡Gracias!"
		let subtitleSuccess = "Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras"
		let btnTitleSuccess = "Ok"

				
		let formData = new FormData()

		for(let key in data) {
			formData.append(key, data[key])
		}

		let namePhoto = generateNameImage()
		let valor = data.rut
		while (valor.indexOf(".") !== -1){
	        valor = valor.replace(".", "")
	    }
	    valor = valor.replace('-','')

		let nameUrl = `${valor}-${namePhoto}.png`

		formData.append("pictures", dataTypeConcourse.pictures, nameUrl);

		let response = await API.postFiles({
									endPoint: endPoints.concourse.createdConcourseCar, 
									data: formData,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: true
								});
		
		let isSuccessFull = response.data.data===null ? false : true

		const message = { 
			success: isSuccessFull, 
			title: isSuccessFull ? titleSuccess : titleError, 
			subtitle: isSuccessFull ? subtitleSuccess : subtitleError, 
			btnTitle: isSuccessFull ? btnTitleSuccess : btnTitleError
		}

		const payload = { message }

		dispatch(requestSuccess(SAVE_FORM_CONCOURSE, payload))

		return { status: isSuccessFull, message: "" }
	}catch(error){
        return { status: false, message: error }
    }
}


export const saveFormDataConcourseMotorcycle = (data, success) => async (dispatch, getState) => {
	
	try{
		const { dataTypeConcourse } = getState().concourses

		let titleError = "¡Ups!"
		let subtitleError = "Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente"
		let btnTitleError = "Reintentar"

		let titleSuccess = "¡Gracias!"
		let subtitleSuccess = "Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras"
		let btnTitleSuccess = "Ok"
				
		let formData = new FormData()

		for(let key in data) {
			formData.append(key, data[key])
		}

		let namePhoto = generateNameImage()
		let valor = data.rut
		while (valor.indexOf(".") !== -1){
	        valor = valor.replace(".", "")
	    }
	    valor = valor.replace('-','')

		let nameUrl = `${valor}-${namePhoto}.png`

		formData.append("pictures", dataTypeConcourse.pictures, nameUrl);		

		let response = await API.postFiles({
									endPoint: endPoints.concourse.createdConcourseMoto, 
									data: formData,
									nameAPI: TOKEN_SITE_WEB, 
									isToken: true,
									isFormData: true
								});
		
		let isSuccessFull = response.data.data===null ? false : true

		const message = { 
			success: isSuccessFull, 
			title: isSuccessFull ? titleSuccess : titleError, 
			subtitle: isSuccessFull ? subtitleSuccess : subtitleError, 
			btnTitle: isSuccessFull ? btnTitleSuccess : btnTitleError
		}

		const payload = { message }

		dispatch(requestSuccess(SAVE_FORM_CONCOURSE, payload))

		return { status: isSuccessFull, message: "" }
	}catch(error){
        return { status: false, message: error }
    }
}

export const saveFormDataConcourseTire = (data, success) => async (dispatch, getState) => {
	
	try{
		const { dataTypeConcourse } = getState().concourses

		let titleError = "¡Ups!"
		let subtitleError = "Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente"
		let btnTitleError = "Reintentar"

		let titleSuccess = "¡Gracias!"
		let subtitleSuccess = "Tu solicitud ha sido enviada con éxito pronto tendrás noticias nuestras"
		let btnTitleSuccess = "Ok"
				
		let formData = new FormData()

		for(let key in data) {
			formData.append(key, data[key])
		}

		let namePhoto = generateNameImage()
		let valor = data.rut
		while (valor.indexOf(".") !== -1){
	        valor = valor.replace(".", "")
	    }
	    valor = valor.replace('-','')

		let nameUrl = `${valor}-${namePhoto}.png`

		formData.append("pictures", dataTypeConcourse.pictures, nameUrl);		

		let response = await API.postFiles({
								endPoint: endPoints.concourse.createdConcourseTire, 
								data: formData,
								nameAPI: TOKEN_SITE_WEB, 
								isToken: true,
								isFormData: true
							});
	
		let isSuccessFull = response.data.data===null ? false : true

		const message = { 
			success: isSuccessFull, 
			title: isSuccessFull ? titleSuccess : titleError, 
			subtitle: isSuccessFull ? subtitleSuccess : subtitleError, 
			btnTitle: isSuccessFull ? btnTitleSuccess : btnTitleError
		}

		const payload = { message }

		dispatch(requestSuccess(SAVE_FORM_CONCOURSE, payload))

		return { status: isSuccessFull, message: "" }
	}catch(error){
        return { status: false, message: error }
    }
}
//Guardar los datos del formulario
export const saveFormDataGlobal = (body) => async (dispatch, getState) => {
    try{
        const { dataTypeConcourse } = getState().concourses
        
        let dataForm = { ...dataTypeConcourse, ...body }
       
        const payload = dataForm

        dispatch(requestSuccess(SAVE_FORM_DATA_GLOBAL_CONCOURSE, payload))

        return { status: true, message: "" }

    }catch(error){
        return { status: false, message: error }
    }
}