import Layout from 'components/Layout/Layout'
import SliderImage from 'components/Home/SliderImage'
import SliderImageLoading from 'components/Home/SliderImageLoading'
import Featured from 'components/Customs/Featured/Featured'
import ServiceCarousel from 'components/Customs/Featured/ServiceCarousel'
import Catalogy from 'components/Customs/CatalogyBrand/Catalogy'
import Search from 'components/Searching/Search'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import MapaAuto from 'components/Home/Used/MapaAuto'
import mapaDerco from 'assets/images/autos_usados.svg'
import Information from 'components/Home/Used/Information'
import Blog from 'components/Blog/Blog'
//import MarqueUrlFriendly from '../../components/Home/MarqueUrlFriendly'


import { homeUsedCar } from 'data/DescripcionHead.json'
import { useHome } from 'hooks/Main/useHome';

const UsedCar = ()  => {

	const { state, actions, routePathCurrent } = useHome("car-used", "car-used")
	
	const { advertisingHomeUsed, listBrandsConcessionaireUsed, listFeaturedCars, listInterestUsed, 
		allBodyWorkUsed, news, loadingPost, location, groupListStoreUsed, 
		advertisingSliderUsed, pageDurationTimerUsed, loadingSlider } = state

	const { actionAdvertising } = actions

	
	return (
		<Layout
			classHeader=""
			hiddenColor={false}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={homeUsedCar}
			classDefaultHeader="row header-top"
			classTopHeader="header__white"
			classMenuHeader="background-top-white"
			typePage={true}
		>
			{
					loadingSlider ? (
						<SliderImageLoading />
					) : (
						<SliderImage 
							banners={advertisingSliderUsed} 
							functionModal={actionAdvertising} 
							options= {{
								className: "slider-header-home",
								navigation: false,
								pagination: advertisingSliderUsed.length===1 ? false : true,
								spaceBetween: 0,
								autoplay: {
									delay: advertisingSliderUsed.length===1 ? null : typeof pageDurationTimerUsed !=="undefined" ? pageDurationTimerUsed : 3000,
								},
								loop: advertisingSliderUsed.length===1 ? false : true,
								speed: advertisingSliderUsed.length===1 ? null  : 6000,
							}}  
						/>
					)
				}

			<Search 
				isClass="card-color" 
				closeVisible={true} 
				isVisibleTypeAuto={false} 
				isVisibleSearch={true}
				typeHomeSelected="car-used"
				routePathCurrent={routePathCurrent}
				lastPage={{
					prevPath: location?.pathname,
					name: "Autos Usados"
				}}
			/>


		{ advertisingHomeUsed?.length>0 && (
			<BannerEvent 
				imagen={advertisingHomeUsed[0]}
				functionModal={actionAdvertising}
				title="Banner" 
				classContainer="mb-0 mt-4" 
				classPicture="car-banner__detail"
			/>
		)}

		<Featured
			classContainer = "tag_container--used"
			title = "Usados Destacados"
			SlideClass="card__feature--used"
			classFeatured="col_featured--used-featured"
			listado={listFeaturedCars?.list}
			classSlide="featured featured-used"
			visibleNavigation={true}
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Usados"
			}}
		 />

		 <ServiceCarousel 
			title="Encuentra el auto que se ajuste a tus "
			spanTitle="intereses"
			listado={listInterestUsed}
			classColor="bg-gray carousel-used-car"
			classContainer="service__container--used"
			classTitle="container__interest__title--used service--used"
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Usados"
			}}
		/>

		<Catalogy
			titleFilter="Filtrar por concesionario"
			listado={listBrandsConcessionaireUsed }
			isClassTitle="catalogy__marca--default"
			isPagination={true}
			classCarousel="carousel__new"
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Usados"
			}}
			groupList={groupListStoreUsed}
		>
			<div className="catoly-title">
				<p className={`panel__title`}>Busca por <span className="panel__title--orange">concesionarios</span></p>
			</div>
		</Catalogy>

		<MapaAuto 
			imagen={mapaDerco}
			title="Derco"
		>
			<p className="panel__title panel__title--new panel__title--map">Encuentra el <span className="panel__title--orange">auto usado</span> que buscas en Movicenter</p>
		</MapaAuto>

		<Information />

		<ServiceCarousel 
			title="Busca tu auto por tipo de  "
			spanTitle="carrocería"
			listado={allBodyWorkUsed}
			classColor="bg-white"
			classContainer="service__container--used"
			classTitle="container__interest__title--used service--used container__interest--carroceria"
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Usados"
			}}
		/>

		<Featured
			classContainer = "tag_container--used_other"
			title = "Usados"
			SlideClass="card__feature--used"
			classFeatured="col_featured--used bg-white"
			listado={listFeaturedCars?.list}
			classSlide="featured featured-used featured-other"
			visibleNavigation={false}
			lastPage={{
				prevPath: location?.pathname,
				name: "Autos Usados"
			}}
		/>


		{ advertisingHomeUsed?.length>0 && (
			<BannerEvent 
				imagen={advertisingHomeUsed[1]}
				functionModal={actionAdvertising}
				title="Banner" 
				classContainer="mb-0 mt-4" 
				classPicture="car-banner__detail"
			/>
		)}

		{/** url_friendly_used.length>0 && <MarqueUrlFriendly  url_friendly={url_friendly_used} />**/}

		 <Blog  
		 	isClass="mt-5" 
			title="Conoce nuestro" 
			subtitle="Blog" 
			list={news} 
			isLoop={true} 
			loading={loadingPost} 
			classCarousel="services-blog"
			titleButton="Ir al blog"
		/>
	
		</Layout>
	)
}

export default UsedCar