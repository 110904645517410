import Layout from 'components/Layout/Layout'
import Featured from 'components/Motorcycle/Carrousel/Featured'
import Blog from 'components/Blog/Blog'
import Ubication from 'components/Home/Initial/Ubication'
import ListMotoByMobile from 'components/Motorcycle/TypeMotorcycle/ListMotoByMobile'
import ListMotoByDsk from 'components/Motorcycle/TypeMotorcycle/ListMotoByDsk'
import MediaQuery from 'react-responsive'

import styles from 'styles/Motorcycle.module.css'
import group_accesorio from 'assets/images/motorcycle/group-accesorio.png'
import { useMotorcycle } from "hooks/Motorcycle/useMotorcycle"

const DetailTypeMoto = () => {

		const { listTypeMotorcycle, motoSport,  featuredMoto, actionAdvertising, advertisingTypeMoto, news, loadingPost } = useMotorcycle(null)

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={{}}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			<section className="row">
				<div className="col-12">
					<section className="container options-search--top">
						<h3 className={styles["title-main"]}>¿Qué tipo de <span className={styles["text-orange"]}>moto</span> buscas?</h3>
						<section className={`${styles["type-moto__container"]} ${styles["type-moto__mb"]}`}>
							{
								listTypeMotorcycle.length>0 &&
								listTypeMotorcycle.map((item, key) => (
									<div className={styles["moto-item"]} key={key}>
										<div className={styles["moto-item__image"]}>
											<img src={item.image} alt={item.title} className="img-fluid" />
										</div>
										<p className={styles["moto-item__title"]}>{item.title}</p>
									</div>
								))
							}
						</section>
					</section>
				</div>
			</section>

			<section className="row">
				<div className="col-12">
					<div className={`container ${styles["moto-need"]} ${styles["moto-need__mt"]}`}>
						<div className={styles["moto-need__header"]}>
							<hr className={styles["moto-findout__line-title"]} />
							<div className={styles["moto-need__image"]}>
								<img src={group_accesorio} alt="Accesorios" className={styles["moto-accesorio"]} />
							</div>
						</div>
						<div className={`${styles["moto-type__group"]} text-center`}>
							<p className={styles["moto-type__subtitle"]}>Motos</p>
							<h3 className={styles["moto-type__title"]}>Sport</h3>
						</div>
					</div>
				</div>
			</section>

			<section className="row">
				<div className="col-12">
					<div className="container">
						{
							Object.keys(motoSport).length>0 && (
								<>
									<MediaQuery maxWidth={767}>
										<ListMotoByMobile 
											infoTypeMoto={motoSport.listGlobalMobile} 
											actionAdvertising={actionAdvertising}
										/>
									</MediaQuery>
									<MediaQuery minWidth={767}>
										<ListMotoByDsk 
											listMoto={featuredMoto?.list}
											actionAdvertising={actionAdvertising}
											advertising={advertisingTypeMoto}
										/>
									</MediaQuery>
								</>
							)
						}					
					</div>
				</div>
			</section>

			<div className={`${styles["bg-f0f0f0"]} row`}>
				<div className={`col-12 ${styles["col-featured"]}`}>
					<div className={`container ${styles["container-featured"]}`}>
						<h4 className={styles["featured-title"]}>Destacados</h4>
						<Featured 
							classSlide="featured-moto__slide"
							visibleNavigation={false}
							listado={featuredMoto?.list}
						/>
					</div>
				</div>
			</div>

			<Ubication isClass="map-link-in" />

			<Blog 
				title="Conoce nuestro" 
				subtitle="Blog" 
				list={news} 
				isLoop={true} 
				loading={loadingPost} 
				classCarousel="services-blog"
				titleButton="Ir al blog"
			/>
			
		</Layout>
	)
}

export default DetailTypeMoto