import styles from 'styles/concourse.module.css'

const CaptureValidatePhoto = ({ panelPreview, screenshotImage, handleResetPhoto, handleNextContact, 
	indexForm, createdPhoto }) => {

	return (
		<section className="preview-photo d-none" ref={panelPreview}>
	    	<p className={`${styles["item-description"]} text-white mt-5 mb-2`}>¿Está seguro con la imagen seleccionada?</p>
			<div className="options-photo">
	    		<img className="screenshot-image" alt="" ref={screenshotImage} />
	    	</div>
	    	<p className={styles["text-base"]}>(*) Bases del concurso en <a href="https://movicenter.cl">www.movicenter.cl</a></p>
	    	<div className="preview-photo__boton">
	    		<button 
		        	type="button" 
		        	className={`btn btn-primary mr-3 ${styles["btn-next"]}`}
		        	onClick={()=>handleResetPhoto(indexForm)}
		        >Borrar</button>
	    		<button 
		        	type="button" 
		        	className={`btn btn-primary ml-3 ${styles["btn-next"]}`}
		        	onClick={()=>handleNextContact(createdPhoto)}
		        >Continuar</button>
	    	</div>
	    </section>
	)
}

export default CaptureValidatePhoto