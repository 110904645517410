import { useEffect } from 'react'
import BaseLayoutSearch from 'components/Layout/BaseLayoutSearch'
import NotificationForm from 'components/Customs/Modal/NotificationForm'
import ModalForm from 'components/Result/ModalForm'
import PanelParamsLeft from 'components/Result/PanelParamsLeft'
import PanelViewRight from 'components/Result/PanelViewRight'
//import MenuSort from 'components/Result/MenuSort'
//import FilterSelectedNormalMobile from 'components/Result/Filter/Mobile/FilterSelectedNormalMobile'
import ButtonCompare from 'components/Result/ButtonCompare'

//import MediaQuery from 'react-responsive'
import { useParams } from 'react-router-dom'

import { useFormContactResult } from "hooks/Contact/useFormContactResult";
import { useResultMain } from "hooks/Result/useResultMain";

const ResultSearchByUrl = () => {

	const paramsUrl = useParams()
	
	const { handleActive, disabledBtn, register,  handleSubmit, 
		onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, handleQuoteCar, closeModalForm, errors, setFormToken } = useFormContactResult()
	
	const { state, actions } = useResultMain()

	const  {
        fetchMoreData,
        searchParamsByUrl,
		actionAdvertising,
		getFileCar,
        goToConcesionaire,
        addCompareCar,
		setMenuSort,
		closeOpenMenuSort,
		setMenuFilter,
		closeOpenMenuFilter
    } = actions
	

	const {
        initialDataNormal, 
        itemOfSearch, 
        titleHeader,
        listResultCar, 
        listCompareCars, 
        isLoadingResult, 
        selectedCarQuote, 
        hasMoreCar,
        descriptionMeta,
		advertisingResult,
		menuSort,
		menuFilter,
		countResult,
		hiddenOrigin
    } = state
	


	useEffect(() => {
		//searchBrands()
		searchParamsByUrl(paramsUrl)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramsUrl])

	return (
		<>
			<BaseLayoutSearch
				descriptionMeta={descriptionMeta} 
				title={titleHeader}
				closeOpenMenuFilter={closeOpenMenuFilter}
				closeOpenMenuSort= {closeOpenMenuSort}
				setMenuSort={setMenuSort}
				menuFilter={menuFilter}
				initialDataNormal={initialDataNormal} 
				setMenuFilter={setMenuFilter}
				itemOfSearch={itemOfSearch}
				menuSort={menuSort}
			>
				<div className="container">
					<div className="row pt-4 pb-5">
						
						<PanelParamsLeft 
							defaultValues={initialDataNormal} 
							itemOfSearch={itemOfSearch}
							advertisingResult={advertisingResult}
							actionAdvertising={actionAdvertising}
							hiddenOrigin={hiddenOrigin}
						/>
						<PanelViewRight 
							listResultCar={listResultCar}
							fetchMoreData={fetchMoreData}
							hasMoreCar={hasMoreCar}
							handleQuoteCar={handleQuoteCar}
							actionAdvertising={actionAdvertising}
							isLoadingResult={isLoadingResult}
							getFileCar={getFileCar} 
							goToConcesionaire={goToConcesionaire}
							addCompareCar={addCompareCar}
							titleHeader={titleHeader}
							menuSort={menuSort} 
							setMenuSort={setMenuSort}
							countResult={countResult}
						/>
					</div>
				</div>
				
				{listCompareCars.length>0 && 
					<ButtonCompare count={listCompareCars?.length} title={titleHeader} />}	
			</BaseLayoutSearch>
			
			{isOpenForm && <ModalForm 
				show={isOpenForm} 
				handleClose={closeModalForm} 
				register={register} 
				handleSubmit={handleSubmit} 
				onSubmit={onSubmit}
				isLoading={isLoading}
				infoSendItem={selectedCarQuote}
				handleActive={handleActive}
				disabledBtn={disabledBtn}
				provider=""
				errors={errors}
				setFormToken={setFormToken}
			/>}
			{isOpen && <NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
		</>
	)
}

export default ResultSearchByUrl