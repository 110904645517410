const API_SITE_MAIN = process.env.REACT_APP_API_URL;
const API_SITE_SECONDARY = process.env.REACT_APP_API_FORM;
const API_SITE_MAINTAINER = process.env.REACT_APP_API_BANNER;
const API_SITE_BLOG = process.env.REACT_APP_API_BLOG;
const API_SITE_COMPARA = process.env.REACT_APP_API_COMPARA

const endPoints = {
  secure: {
    generateTokenManintaner: `${API_SITE_MAINTAINER}/token/generate`,
    generateTokenSite: `${API_SITE_MAIN}/token/generate`,
  },
  advertising: {
     getList: `${API_SITE_MAINTAINER}/banner/getBanners`,
     saveClic: `${API_SITE_MAIN}/banner/insert`,
     getPages: `${API_SITE_MAINTAINER}/pages/getPages`,
  },
  blog: {
      getList: `${API_SITE_BLOG}/wp-json/wp/v2/blog?_embed=1`
  },
  car: {
    getCarsInterest: `${API_SITE_MAIN}/car/getCarsInterest`,
    getCarsFilterNormal: `${API_SITE_MAIN}/car/getCarsFilter`,
    getCarsKey: `${API_SITE_MAIN}/car/getCarsKey`,
    formContacts: `${API_SITE_SECONDARY}/form-contacts`,
    getDestacados: `${API_SITE_MAIN}/car/getDestacados`,
    getCarsRecommended: `${API_SITE_MAIN}/car/getCarsRecommended`,
    scheduledSearch: `${API_SITE_MAIN}/customer/scheduledSearch`,
    getCarsFilterCombined: `${API_SITE_MAIN}/car/getCarsCombined`,
  },
  concourse: {
    createdConcourseCar: `${API_SITE_MAIN}/contest/create`,
    createdConcourseMoto: `${API_SITE_MAIN}/contest/motorcycle/create`,
    createdConcourseTire: `${API_SITE_MAIN}/contest/tire/create`
  },
  contact: {
    formSend: `${API_SITE_SECONDARY}/form-contacts/contact`,
  },
  coupon: {
    exchange: `${API_SITE_SECONDARY}/discount-coupons/exchange`
  },
  electrical: {
    listScooter: `${API_SITE_MAIN}/electricscooter/all`,
    listBicycle: `${API_SITE_MAIN}/electricbicycle/all`,
    getFeatured: `${API_SITE_MAIN}/electricscooter/getHighlighted`,
  },
  store: {
      providerBySlug: (slug) => `${API_SITE_SECONDARY}/providers/slug/${slug}`,
      getCarsConcessionaire: `${API_SITE_MAIN}/car/getCarsConcessionaire`,
      categoryProviders: `${API_SITE_SECONDARY}/category-providers`,
      providers: (authorized) => `${API_SITE_SECONDARY}/providers?filter[authorized]=${authorized}`,
      providerServicesBySlug: (slug) => `${API_SITE_SECONDARY}/providers/services/slug/${slug}`,
      getServicesByProviderId: (id) => `${API_SITE_SECONDARY}/providers/${id}/categories`
  },
  brands: {
    getCarBrands: `${API_SITE_MAIN}/car/getCarBrands`,
  },
  motorcyle: {
    getMotorcyles: `${API_SITE_MAIN}/motorcycle/all`,
    getMotorcycleById: `${API_SITE_MAIN}/motorcycle/one`,
    getFeatured: `${API_SITE_MAIN}/motorcycle/getDestacados`
  },
  compara: {
    login: `${API_SITE_COMPARA}/auth/login`,
    getBrand: `${API_SITE_COMPARA}/cars/brands`,
    getModel: (brandId) => `${API_SITE_COMPARA}/cars/brands/${brandId}/models`,
    getYear: `${API_SITE_COMPARA}/cars/years`,
    getComuna: `${API_SITE_COMPARA}/geo/communes`,
    registerLead: `${API_SITE_MAIN}/parner/registerLead`,
    interested: `${API_SITE_MAIN}/insurance/interested`,
    offersSelected: `${API_SITE_COMPARA}/offers/selected`,
  },
  soap: {
    registerInsurance: `${API_SITE_MAIN}/insurance/registerInsurance`,
    updateInsurance: `${API_SITE_MAIN}/insurance/updateInsurance`,
  },
  olx: {
    getBrand: `${API_SITE_MAIN}/parner/getBrand`,
    getModel: `${API_SITE_MAIN}/parner/getModel`,
    getYear: `${API_SITE_MAIN}/parner/getYear`,
    getVersion: `${API_SITE_MAIN}/parner/getVersion`,
  },
  events: {
    sendLoads: `${API_SITE_MAIN}/event/lead`,
  },
  offer: {
    getList: `${API_SITE_MAINTAINER}/offer/get`,
  }
};

export default endPoints;