import { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import PanelService from 'components/Services/Panel/PanelService'
import Cards from 'components/Customs/Panels/Cards'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import Services from 'components/Customs/Featured/Services'
import MapaAuto from 'components/Home/Used/MapaAuto'
import services_and_accessories from 'assets/images/services_and_accessories.svg'
import CardFilterNormal from 'components/Customs/Panels/CardFilterNormal'

import { useAdvertising } from 'hooks/useAdvertising'
import { getListInitialServices, searchServiceBySlug } from 'services/actions/serviceActions'
import { useDispatch, useSelector } from 'react-redux'

import { useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useConfig } from "hooks/MetaHead/useConfig"

const Service = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const { register, handleSubmit, errors } = useForm()
	const { servicesAll, viewPageService  } = useSelector((state) => state.services)
	const { advertisingServices, actionAdvertising, getAdvertisingInitial } = useAdvertising()
	const { generateMetaServiceGlobal } = useConfig()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})


	const onSubmit = async (data) => {
		if(data.typeOfService!=="neumaticos"){
			const response = await dispatch(searchServiceBySlug(data.typeOfService))
			if(response.status && response?.url!==""){
				history.push(response.url)
			}
		}else{
			history.push("/servicio-automotriz/neumaticos")
		}
		
	}

	const gotToBack = () => {
		history.go(-1)
	}

	const getDataService = async () => {

		await getAdvertisingInitial()
		
		const infoMeta = generateMetaServiceGlobal(location?.pathname)
		setDescriptionMeta(infoMeta)
		if(servicesAll.length===0){
			await dispatch(getListInitialServices())
		}		
	}

	useEffect(() => {
		getDataService()
		//ReactGA.pageview(window.location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			<PanelService
				backgroundMobile="service__backdrop--ser"
				classReturn="d-tire__return"
				gotToBack={gotToBack}
				title="Servicio Mecánico Automotriz"
				classTitle="services__title"
			>
				<Cards classBody="" classContainer="service-container">
					<form onSubmit={handleSubmit(onSubmit)} className="w-100">
						<CardFilterNormal title="Tipo de servicio" isClassMb={"mb-0"}>
							<div className="search-advanced_form w-100">
								<select 
									id="typeOfService" 
									name="typeOfService"
									className="form-control search-advanced_form__control"
									ref={register({required: {value: true, message: 'Requerido'}})} 
								>
								  <option value="">Selecciona servicio</option> 
								  {
								  	viewPageService.map((item, index) => 
								  		<option key={index} value={item.slug}>{item.name}</option>)
								  }
								</select>
								{errors.typeOfService && (
							    	<span className="text-danger">
							      		{errors?.typeOfService?.message}
							    	</span>
							    )} 
							</div>
							<div className="w-100 mt-3 mb-3">
					    		<button type="submit" className="btn btn-primary btn-lg w-100">Buscar</button>
					    	</div>
						</CardFilterNormal>
					</form>
				</Cards>
			</PanelService>
			
			{
				advertisingServices.length>0 && (
					<BannerEvent 
						imagen={advertisingServices[0]}
						functionModal={actionAdvertising}
						title="Banner" 
						classContainer="mb-5 mt-4" 
						classPicture="car-banner__detail"
					/>
				)
			}			

			<Services 
				title="Una ciudad pensada en los automóviles"
				listado={viewPageService}
				classColor="bg-white city_car--default"
				classContainer="service__container--default"
				classTitle="container__interest__title--service"
			/>


			<MapaAuto 
				imagen={services_and_accessories}
				title="Derco"
			>
				<p className="panel__title panel__title--service panel__title--service__top">Encuentra el <span className="panel__title--orange">servicio</span> que buscas en Movicenter</p>
			</MapaAuto>
		</Layout>
	)
}

export default Service