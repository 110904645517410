import { useState } from "react";
import { useForm } from "react-hook-form";
import { getListOffer, getLoadingOffer } from 'services/actions/offerAction'
import { useDispatch, useSelector } from 'react-redux'
import { useGoogleRecaptcha } from 'hooks/Contact/useGoogleRecaptcha';
import { sendFormNotificationByBrand } from 'services/actions/notificationAction';
import { useHistory, useLocation } from "react-router-dom";
import { getLabelMonth } from 'utils/toolDate'


export const useOffer = () => {

	const dispatch = useDispatch()

	const {  listOfferAll, loadingOffer, offerMain } = useSelector((state) => state.offers)

	const history = useHistory()
	const location = useLocation()
	const { getToken } = useGoogleRecaptcha();

	const { register, handleSubmit, errors, reset } = useForm();
	const [ disabledBtn, setDisabledBtn ] = useState(true);
	const [ isOpenForm, setIsOpenForm ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ itemSelectedListForm, setItemSelectedListForm] = useState("");
	const [ title, setTitle ] = useState("")

	const [ message, setMessage ] = useState({
		error: false, 
		title: '¡Ups!', 
		subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente', 
		btnTitle: 'Reintentar',
		success: false
	})

	const getDataInitial = async () => {
        dispatch(getLoadingOffer(true))
		if(listOfferAll.length===0){
			await dispatch(getListOffer())
		}
		dispatch(getLoadingOffer(false))	
		setTitle(getLabelMonth(new Date().getMonth()))
	}

	const closeModal = () => setIsOpen(false)

	const closeModalForm = () => setIsOpenForm(false)

	const handleActive = (event) => setDisabledBtn(!event.target.checked);

	const getSelectOffer = (item) => {

		setItemSelectedListForm(item);
		setIsOpenForm(!isOpenForm)
	}

	const onSubmit =  async (data, provider) => {
		try{
			setIsLoading(true)
			setMessage({error: false, title: '', subtitle: '', btnTitle: '', success: false});
			setDisabledBtn(true);
		
			const tokenCaptcha = await getToken();
	
			let dataForm = {
				name: data.name,
				telephone: "+569"+data.telephone,
				email: data.email,
				message: `${data.message} - Solicitud desde la oferta de la categoría: ${provider?.category}`,
				provider: provider?.title,
				token: tokenCaptcha
			}
	
	
			const response = await dispatch(sendFormNotificationByBrand(dataForm, location.pathname))
			
			if(response.status){
				reset({
					name: '',
					telephone: '',
					email: '',
					message: ''
				})
				setIsLoading(false)
				setIsOpenForm(!isOpenForm)
				setDisabledBtn(false)
				history.push('/success')
			}else{
				setMessage({
					error: false, 
					title: '¡Ups!', 
					subtitle: 'Ha ocurrido error en el envío del formulario. Por favor intenta nuevamente',
					btnTitle: 'Reintentar',
					success: false
				})
				setIsLoading(false)
				setIsOpenForm(!isOpenForm)
				setIsOpen(true)
				setDisabledBtn(false)
			}	
		}catch(error){
			setMessage({
				error: false, 
				title: '¡Ups!', 
				subtitle: 'Ha ocurrido un error en el envío del formulario. Por favor recargue la página e intenta nuevamente',
				btnTitle: 'Reintentar',
				success: false
			})
			setIsLoading(false)
			setIsOpenForm(!isOpenForm)
			setIsOpen(true)
			setDisabledBtn(false)
		}
			
	}
	
	const formElement = {
		handleActive,
		disabledBtn,
		register, 
		handleSubmit, 
		onSubmit,
		message,
		isOpen, 
		closeModal,
		isLoading,
		errors,
		isOpenForm,
		getSelectOffer,
		closeModalForm
	}

	return {
		listOfferAll,
		loadingOffer,
        getDataInitial,
		formElement,
		itemSelectedListForm,
		offerMain,
		title
	}
}