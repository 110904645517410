import { selectedSendFormMyRoute } from "services/actions/carActions"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const useRoute = () => {
	
	const dispatch = useDispatch()
    const history = useHistory()
    const { listCarMyRoute, isLoadingMyRoute } = useSelector((state) => state.cars)
	
    const selectedItemFormMyRoute = (event) => {
        let { value, checked } = event.target

       dispatch(selectedSendFormMyRoute(value, checked))
    }

    const historyBack = () => {
		history.go(-1)
	}

    const state = {
        listCarMyRoute, 
        isLoadingMyRoute
    }

    const actions = {
        selectedItemFormMyRoute,
        historyBack
    }

	return {
		state,
        actions
	}
}