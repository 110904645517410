import CaptureValidatePhoto from 'components/Concourse/Custom/CaptureValidatePhoto'
import delete_menu from 'assets/images/icons/delete-menu.svg'
import take_photo from "assets/images/concourse/take_photo.svg"
//import icon_1 from "assets/images/concourse/1.svg"
//import icon_2 from "assets/images/concourse/2.svg"
import styles from 'styles/concourse.module.css'

const FormCapturePhoto = ({ videoPhoto, canvasPhoto, handleCapturePhoto, listDevices,
	panelTakePhotoPreview, panelTakePhotoSelected, screenshotImage, handleResetPhoto, 
	handleNextContact, handleResetCapturePhoto, handleChangeSelected, title, info_1, info_3 }) => {

	return (
		<>
			<div className={styles["list-item"]}>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>1</p>
					</div>
				    <p className={styles["item-description"]}>{info_1}</p>
				</div>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>2</p>
					</div>
				    <p className={styles["item-description"]}>Asegura que en la fotografía, la factura aparezca completa y sea legible.</p>
				</div>
			</div>
			<section className="selected-photo" ref={panelTakePhotoSelected}>
				<div className="options-photo">
					<video muted="muted" autoPlay ref={videoPhoto}></video>
			    	<canvas className="d-none"  ref={canvasPhoto}></canvas>
		    	</div>
		    	{listDevices.length>1 && (
		    		<div className="form-group quote-contact__form">
	                    <label htmlFor="comuna" className="quote-contact__label">Camaras</label>
	                    <select 
	                        className="form-control form-quote__select form-quote__select--height" 
	                        id="camara_id" 
	                        name="camara_id"
	                        onChange={(event) =>handleChangeSelected(event)}
	                    >
	                    	{
	                    		listDevices.length>0 &&
	                    		listDevices.map(device => <option value={device.deviceId}>{device.label}</option>)
	                        
	                    	}
	                        
	                    </select>
	                </div>
	            )}
		    	<div className={styles["options-capture"]}>
		    		<button 
			        	type="button" 
			        	className={`${styles["btn-circle-primary"]}`}
			        	onClick={()=>handleResetPhoto(0)}
			        ><img src={delete_menu} alt="Volver" /></button>
			        <button 
			        	type="button" 
			        	className={`${styles["btn-circle-primary"]}`}
			        	onClick={()=>handleCapturePhoto()}
			        ><img src={take_photo} alt="Capturar" /></button>
		    	</div>
		    </section>
		    <CaptureValidatePhoto
		    	panelPreview={panelTakePhotoPreview} 
		    	screenshotImage={screenshotImage} 
		    	handleResetPhoto={handleResetCapturePhoto} 
		    	handleNextContact={handleNextContact}
		    	indexForm={1}
		    	createdPhoto={true}
		    />
	   	</>
	)
}

export default FormCapturePhoto