import { useEffect } from 'react'
import { getListTypeMotorcycle, getListTBrandMotorcycle, getListFeatured,
		getInfoMotorcycle, loadingInfoMotorcycle } from 'services/actions/motorcycleAction'
import { getListNews } from 'services/actions/blogActions'
import { useDispatch, useSelector } from 'react-redux'
import { PathTypeCurve } from 'utils/MotorcycleFunction/TypeCurve'
import { useAdvertising } from 'hooks/useAdvertising'
import { useFeatured } from 'hooks/useFeatured'

export const useMotorcycle = (id) => {
	
	const dispatch = useDispatch()
	const { motorcycles, blog } = useSelector((state) => {
				return {
					motorcycles: state.motorcycles,
					blog: state.blog
				}
			})

	const { listTypeMotorcycle, listBrandMotorcycle, motoScooter, motoSport, 
		motoTrail, motoATV, featuredMoto, infoMotorcycle, isLoadingMoto, isLoadingMotoHome } = motorcycles
	const { news, loadingPost } = blog

	const { advertisingTypeMoto, getListAdvertising } = useAdvertising()
	const { getLoadListProvider, getLoadListSuppliers } = useFeatured()


	const getListMotorcycle = async (id) => {
		try{
	
			dispatch(loadingInfoMotorcycle(true))
			
			await getListAdvertising()
			
			await getLoadListProvider()

			await getLoadListSuppliers()

			if(listTypeMotorcycle.length===0){
				await dispatch(getListTypeMotorcycle())
			}
			if(listBrandMotorcycle.length===0){
				dispatch(getListTBrandMotorcycle())
			}
			if(featuredMoto?.list.length===0){
				await dispatch(getListFeatured())
			}
			if(id!==null){
				await dispatch(getInfoMotorcycle(id))
			}	

			if(news.length===0){
				await dispatch(getListNews())
			}

			dispatch(loadingInfoMotorcycle(false))
		}catch(error){
			return { status: error }
		}
	}

	const handleMoveTo = (event, idPanel) => {
		event.preventDefault()
		document.getElementById(idPanel).scrollIntoView(true)
	}


	useEffect(() => {
		getListMotorcycle(id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return {
		listTypeMotorcycle,
		listBrandMotorcycle,
		motoScooter,
		motoSport,
		motoTrail,
		motoATV,
		featuredMoto,
		picture: PathTypeCurve.picture3,
		infoMotorcycle,
		isLoadingMoto,
		advertisingTypeMoto,
		isLoadingMotoHome,
		handleMoveTo,
		news,
		loadingPost
	}
}