import { useEffect, useState } from 'react'
//import ReactGA from 'react-ga'
import PostCards from 'components/Blog/PostCards';
import Layout from 'components/Layout/Layout'
import { getListNews, getInfoPost } from 'services/actions/blogActions'
import down_arrow_left from 'assets/images/icons/down-arrow-left.svg'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//import { homeBlog } from "data/DescripcionHead.json"

const SinglePost = () => {

    const dispatch = useDispatch()
    const { slug } = useParams()
    const history = useHistory()
    const [ homeMeta, setHomeMeta ] = useState({
        title: "",
        description: "",
        url: ""
    })
    const { listViewBlog, infoPost  } = useSelector((state) => state.blog)

    const getListPosts = async () =>{
        if(listViewBlog.length===0){
            await dispatch(getListNews())
        }
        const response = await dispatch(getInfoPost(slug)) 
        if(Object.keys(response.payload).length>0){
            const { yoast_head_json } = response.payload
            setHomeMeta({
                title: yoast_head_json.title,
                description: yoast_head_json.description,
                url: yoast_head_json.canonical
            })                     
        }
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        getListPosts()
        //ReactGA.pageview(window.location.pathname)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])

    const historyBack = () => {
        history.go(-1)
    }
    

     return(
        <Layout
            classHeader="row header__white"
            hiddenColor={true}
            isVisibleFooter={true}
            visibleLine={true}
            infoMetaHead={homeMeta}
            classDefaultHeader="row header-top header__white"
			classTopHeader=""
        >

            <div className="row options-search--top">
                {
                    Object.keys(infoPost).length>0 && (
                        <>
                            <div className="col-12 my-blog-back">
                                <div className="container">
                                    <div className="text-right pt-2">
                                        <div 
                                            className="my-route-back text-white" 
                                            onClick={historyBack}>
                                        <img src={down_arrow_left} alt="Volver" />Volver</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="container pt-md-5 mt-5 mb-5 pb-5">
                                    <h1 className="blog__title my-5">{infoPost.title.rendered}</h1>
                                    <div className="container-title">
                                        <p className="blog__content__title pr-md-4">{infoPost.formatted_date}</p>
                                        <p className="blog__content__title content__title_border" >Tiempo de lectura: 6 min</p>
                                    </div>
                                    <div className="blog__content__text mt-3" dangerouslySetInnerHTML={{ __html: infoPost.content.rendered}} /> 
                                </div>
                            </div>         
                        </>         
                    )
                }
            </div>
            <div className="row row-last">
                <div className="col-12">
                    <div className="container">
                        <h1 className="mb-1 title-posts">Artículos anteriores</h1>
                        <div className="line__article"></div>
                        <div className="blog-row">
                            {
                                listViewBlog.map((post, key) => (
                                    <PostCards key={key} {...post} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SinglePost