import Layout from 'components/Layout/Layout'
import BannerEventFill from 'components/Customs/Banner/Horizontal/BannerEventFill'
import DetailMotorcycle from 'components/Motorcycle/DetailMotorcycle'
import Featured from 'components/Motorcycle/Carrousel/Featured'
import Blog from 'components/Blog/Blog'
import Ubication from 'components/Home/Initial/Ubication'
import BgCurve from 'components/Motorcycle/Custom/BgCurve'
import ItemTypeMotorcycle from 'components/Motorcycle/ItemTypeMotorcycle'
import BrandMotorcycle from 'components/Motorcycle/BrandMotorcycle'

import styles from 'styles/Motorcycle.module.css'

import group_mmoto_mobile from 'assets/images/motorcycle/group-motorcycle-dsk.png'
import group_accesorio from 'assets/images/motorcycle/group-accesorio.png'

import { useMotorcycle } from "hooks/Motorcycle/useMotorcycle"
import { useAdvertising } from 'hooks/useAdvertising'


const HomeMotorcycle = () => {

	const { listTypeMotorcycle, listBrandMotorcycle, motoScooter, 
		motoSport, motoTrail, motoATV, featuredMoto, picture, isLoadingMotoHome,
		handleMoveTo, news, loadingPost } = useMotorcycle(null)
	const { advertisingMoto, actionAdvertising } = useAdvertising()

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={{}}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			<section className="row">
				<div className={`col-12 ${styles["main__header"]}`}>
					<section className={`container ${styles["main__container"]}`}>
						<div className={styles["main__title"]}>
							<h3 className={styles["title-h3"]}>Para trabajar, de rutas, deportivas, etc…</h3>
							<h1 className={styles["title-h1"]}>La moto que quieres la encuéntras aquí</h1>
						</div>
						<div className={styles["main__moto"]}>
							<img src={group_mmoto_mobile} alt="La moto que quieres la encuéntras aquí" className={styles["main__image"]} />
						</div>
					</section>
				</div>	
				<BgCurve
					pathDefault={picture?.mobile}
					pathDsk={picture?.dsk}
					bgColor="#F47422"
				/>
			</section>
			<section className="row">
				<div className="col-12">
					<section className="container">
						<h3 className={styles["title-main"]}>¿Qué tipo de <span className={styles["text-orange"]}>moto</span> buscas?</h3>
						<section className={styles["type-moto__container"]}>
							<ItemTypeMotorcycle 
								listTypeMotorcycle={listTypeMotorcycle}
								isLoading={isLoadingMotoHome}
								handleMoveTo={handleMoveTo}
							/>
						</section>
					</section>
				</div>
			</section>
			<section className="row">
				<div className={`col-12 ${styles["brand-row"]}`}>
					<section className="container">
						<section className={styles["brand-group"]}>
							<p className={styles["title-brand"]}>Nuestras marcas</p>
							<section className={styles["brand-container"]}>		
								<BrandMotorcycle
									listBrandMotorcycle={listBrandMotorcycle}
									isLoading={isLoadingMotoHome}
								/>				
							</section>
						</section>
					</section>
				</div>
			</section>
			{ advertisingMoto?.length>0 && (
				<BannerEventFill 
					imagen={advertisingMoto[0]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="banner-moto__main"
					classMain=""
				/>
			)}

			<section className="row">
				<div className="col-12">
					<div className={`container ${styles["moto-need"]}`}>
						<div className={styles["moto-need__header"]}>
							<hr className={styles["moto-findout__line-title"]} />
							<div className={styles["moto-need__image"]}>
								<img src={group_accesorio} alt="Accesorios" className={styles["moto-accesorio"]} />
							</div>
						</div>
						<h3 className={styles["moto-need__title"]}>¿Cuál es la moto que necesitas?</h3>
						<p className={`mb-0 mt-4 ${styles["moto-type__info"]}`}>Descubre tu tipo de moto en un solo lugar</p>
					</div>
				</div>
			</section>


			<DetailMotorcycle 
				infoMoto={motoScooter} 
				classBtn="moto-findout__scooter" 
				bgColor="bg-fff"
				typeViewMoto="square"
				classTitle=""
				orderText="order-0 order-md-0"
				orderImage="order-1 order-md-1"
				loading={isLoadingMotoHome}
				lastPage={{
					prevPath: "/motos",
					name: "Motos"
				}}
			/>


			{ advertisingMoto?.length>0 && (
				<BannerEventFill 
					imagen={advertisingMoto[1]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="banner-moto__type"
					classMain=""
				/>
			)}


			<DetailMotorcycle 
				infoMoto={motoSport} 
				classBtn="moto-findout__sport" 
				bgColor="bg-f0f0f0"
				typeViewMoto="curve"
				classTitle=""
				orderText="order-0 order-md-1"
				orderImage="order-1 order-md-0 ml-md-0"
				loading={isLoadingMotoHome}
				lastPage={{
					prevPath: "/motos",
					name: "Motos"
				}}
			/>

			<DetailMotorcycle 
				infoMoto={motoTrail} 
				classBtn="moto-findout__scooter" 
				bgColor="bg-fff"
				typeViewMoto="square"
				classTitle="mt-text"
				loading={isLoadingMotoHome}
				lastPage={{
					prevPath: "/motos",
					name: "Motos"
				}}
			/>

			{ advertisingMoto?.length>0 && (
				<BannerEventFill 
					imagen={advertisingMoto[2]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="banner-moto__type"
					classMain=""
					orderText="order-0 order-md-0"
					orderImage="order-1 order-md-1"
				/>
			)}


			<DetailMotorcycle 
				infoMoto={motoATV} 
				classBtn="moto-findout__sport" 
				bgColor="bg-f0f0f0"
				typeViewMoto="curve"
				classTitle=""
				orderText="order-0 order-md-1"
				orderImage="order-1 order-md-0 ml-md-0"
				loading={isLoadingMotoHome}
				lastPage={{
					prevPath: "/motos",
					name: "Motos"
				}}
			/>

			<div className={`row ${styles["find-site__row"]}`}>
				<div className="col-12">
					<div className="container">
						<p className={styles["find-site__title"]}>En Movicenter descubre más de 500 autos todos en un mismo lugar.</p>
						{ advertisingMoto?.length>0 && (
							<BannerEventFill 
								imagen={advertisingMoto[3]}
								functionModal={actionAdvertising}
								title="Banner" 
								classContainer="mb-0" 
								classPicture="banner-moto__find"
								classMain=""
							/>
						)}
					</div>
				</div>
					
			</div>

			<div className={`${styles["bg-f0f0f0"]} row`}>
				<div className={`col-12 ${styles["col-featured"]}`}>
					<div className={`container ${styles["container-featured"]}`}>
						<h4 className={styles["featured-title"]}>Destacados</h4>
						<Featured 
							classSlide="featured-moto__slide"
							visibleNavigation={false}
							options={featuredMoto}
							lastPage={{
								prevPath: "/motos",
								name: "Motos"
							}}
						/>
					</div>
				</div>
			</div>

			<Ubication isClass="map-link-in" />

			<Blog 
				title="Conoce nuestro" 
				subtitle="Blog" 
				list={news} 
				isLoop={true} 
				loading={loadingPost} 
				classCarousel="services-blog"
				titleButton="Ir al blog"
			/>

		</Layout>		
	)
}

export default HomeMotorcycle