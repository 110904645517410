import { useEffect } from 'react'
import BaseLayoutStore from 'components/Layout/BaseLayoutStore'
import NotificationForm from 'components/Customs/Modal/NotificationForm';
import ModalForm from 'components/Result/ModalForm'
import FormContactStore from 'components/Customs/Contact/FormContactStore'
import { useParams } from 'react-router-dom'
import { homeService } from "data/DescripcionHead.json"
import { useMain } from "hooks/Service/useMain"

const DetailSellYourCar = () => {

    const { slug } = useParams()
	const { state, actions, formElement } = useMain()

	const { serviceSelected, itemSelectedListForm, loadingCategoriesProvider, isDataService } = state
	const { getDataServiceProvider, handleMoveTo } = actions 
	const { handleActive, disabledBtn, register,  handleSubmit, onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, closeModalForm, errors, setFormToken } = formElement
	

	useEffect(() => {
		getDataServiceProvider(slug)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	return (
		<BaseLayoutStore
			infoMetaHead={homeService}
			infoHeader={serviceSelected} 
			classStyle={{
				classTitle: "header-separator"
			}}
			loading={loadingCategoriesProvider}
			isDataService={isDataService}
			isVisibleIcon={true} 
			handleMoveTo={handleMoveTo}
		>	

			<div className="row">
				<div className="col-12 mt-5">
					<FormContactStore 
						isVisibleVIN={false} 
						itemService={serviceSelected}
						styleForm={{main:"service-form-contact"}}
						provider={serviceSelected?.name}
					/>
				</div>
			</div>

			{isOpenForm && <ModalForm 
				show={isOpenForm} 
				handleClose={closeModalForm} 
				register={register} 
				handleSubmit={handleSubmit} 
				onSubmit={onSubmit}
				isLoading={isLoading}
				infoSendItem={itemSelectedListForm}
				handleActive={handleActive}
				disabledBtn={disabledBtn}
				provider={serviceSelected}
				errors={errors}
				setFormToken={setFormToken}
				checkValue={false}
			/>}
            {isOpen && <NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
		</BaseLayoutStore>
		
	)
}


export default DetailSellYourCar