import { useEffect, useState } from 'react'
import Layout from 'components/Layout/Layout'
import MainPanel from 'components/Services/Custom/MainPanel'
import ItemService from 'components/Services/ResultSearchService/ItemService'
import MapaAuto from 'components/Home/Used/MapaAuto'
import FormContactService from 'components/Customs/Contact/FormContactService'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import ModalSpinner from 'components/Customs/Modal/ModalSpinner'

import { useParams, useLocation } from 'react-router-dom'


import imgMobile from 'assets/images/background/services.png'
import imgDsk from 'assets/images/background/home_service_desktop.jpg'
import services_and_accessories from 'assets/images/services_and_accessories.svg'

import { useConfig } from "hooks/MetaHead/useConfig"
import { useMain } from "hooks/Service/useMain"

const Detail = () => {

	const { slug } = useParams()
	const location = useLocation()

	const { generateMetaServiceGlobal } = useConfig()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

	const { state, actions } = useMain()

	const { resultServices, advertisingServices } = state
	const { getDataService, actionAdvertising } = actions 
	

	useEffect(() => {
		const infoMeta = generateMetaServiceGlobal(location?.pathname)
		setDescriptionMeta(infoMeta)
		getDataService(slug)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slug])

	
	const renderBanner = (slug, advertising) => {
		
		switch (slug) {
			case 'baterias': 
				return advertising[3];
			case 'llantas-autos': 
				return advertising[4];
			case 'alineacion-y-balanceo': 
				return advertising[2];
			case 'cambio-de-aceite': 
				return advertising[1];
			default:
				return advertising[0];
		};
	}

	return (
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={descriptionMeta}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			
			<MainPanel
				title={resultServices?.service!=="undefined" ? resultServices?.service.titleH1 :  "Servicio Mecánico Automotriz"}
				imgMobile={imgMobile}
				imgDsk={imgDsk}
			/>

			{resultServices.isLoading && <ModalSpinner message="Espera un momento, estamos buscando los servicios" />}	
			{(resultServices?.service) && (
				<div className="row bg-gray">
					<div className="col-12">
						<div className="container services-container">
							<p className="services-container--small">{resultServices.list.length} Resultados de la búsqueda</p>
							<div className="services-primary">
								{
									resultServices.list.length>0 && resultServices.list.map((item, key) => 
											<ItemService
												key={key}
												title={item.name}
												imagen={item.cover_image_mobile}
												url={`/tienda-servicio-automotriz/${item.slug}`}
												lastPage={{
													prevPath: location?.pathname,
													name: resultServices?.service!=="undefined" ? resultServices?.service.titleH1 :  "Servicio Mecánico Automotriz"
												}}
											/>
									)
								}
							</div>
						</div>
					</div>
				</div>
			)}	

			{
				advertisingServices.length >0 && (
					<BannerEvent 
						imagen={renderBanner(slug, advertisingServices)}
						functionModal={actionAdvertising}
						title="Banner" 
						classContainer="mb-5 mt-4" 
						classPicture="car-banner__detail"
					/>
				)
			}

			<MapaAuto 
				imagen={services_and_accessories}
				title="Derco"
			>
				<p className="panel__title panel__title--service mt-5">Encuentra el <span className="panel__title--orange">servicio</span> que buscas en Movicenter</p>
			</MapaAuto>

			<div className="row gray-form-contact form-contact--top">
				<div className="col-12">
					<FormContactService 
						isVisibleVIN={false} 
						styleForm={{main:"service-form-contact--white"}}
						itemService={resultServices?.service?.name}
						provider={null}
					/>
				</div>
			</div>
			
		</Layout>
	)
}

export default Detail