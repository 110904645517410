import { useEffect, useState } from 'react'

import BaseLayoutStore from 'components/Layout/BaseLayoutStore';
import NotificationForm from 'components/Customs/Modal/NotificationForm';
import ItemRemolque from 'components/Concessionaire/ItemRemolque';
import ModalSpinner from 'components/Customs/Modal/ModalSpinner';
import ModalForm from 'components/Result/ModalForm'

import { useLocation } from 'react-router-dom'
import { useConfig } from "hooks/MetaHead/useConfig"

import { useFunctionBaseConcesionaire } from "hooks/Concesionaire/useFunctionBaseConcesionaire";

const HomeChileCaravan = () => {

    const location = useLocation()
	const [ descriptionMeta, setDescriptionMeta ] = useState({})

    const { actions, state, formElement } = useFunctionBaseConcesionaire()

    const { detailConcessionare, listCarsAssign, carSelectedQuote, isLoadingSearch } = state

    const  { getDataInitialHome, selectedQuote } = actions

    const { handleActive, disabledBtn, register,  handleSubmit, onSubmit, message, isOpen, closeModal, isLoading,
		isOpenForm, closeModalForm, errors 	} = formElement
	
	const { generateMetaHeadGlobal } = useConfig()
	
	const getDataInitial = async (slug) => {
		const infoMeta = generateMetaHeadGlobal(location?.pathname)
		setDescriptionMeta(infoMeta)
		await getDataInitialHome(slug)		
	}

	useEffect(() => {
		getDataInitial("chile-caravan")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.pathname])


	return (
        <BaseLayoutStore
			infoMetaHead={descriptionMeta}
			infoHeader={detailConcessionare} 
			classStyle={{
				classTitle: "header-separator"
			}}
		>
			
            <div className="row bg-concessionaire">
                <div className="col-12 service-result">
                    <div className="container">
                        <div className="service-result">
                            {
                                listCarsAssign.length=== 0 
                                ?
                                <p className="service-result__h1">No hay resultados</p>
                                :
                                (
                                    <div className="service-result__title">
                                        <p className="service-result__h1">Búsqueda</p>
                                        <p className="service-result__small">{listCarsAssign?.length} Resultados</p>
                                    </div>
                                )
                            }
                        </div>
                        <div className="service-result__container">
                            {
                                listCarsAssign.map((item, index) => 
                                    <ItemRemolque 
                                        key={index} 
                                        {...item} 
                                        selectedQuote={selectedQuote} 
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

			{isLoadingSearch.status && 
				<ModalSpinner 
						message={isLoadingSearch.message}
					/>
			}

			{isOpenForm && <ModalForm 
				show={isOpenForm} 
				handleClose={closeModalForm} 
				register={register} 
				handleSubmit={handleSubmit} 
				onSubmit={onSubmit}
				isLoading={isLoading}
				infoSendItem={carSelectedQuote}
				handleActive={handleActive}
				disabledBtn={disabledBtn}
				provider={detailConcessionare?.name}
				errors={errors}
			/>}
            {isOpen && <NotificationForm 
					show={isOpen} 
					handleClose={closeModal} 
					message={message}
				/>
			}
		</BaseLayoutStore>
	)
}

export default HomeChileCaravan