import MetaTagUsed from "hooks/MetaHead/MetaTagUsed.json"
import MetaTagNew from "hooks/MetaHead/MetaTagNew.json"

const YEAR_DEFAUTL = 2000

export const generateFormatURLPrice = (value) => {
    let valuePrice = ""

    let namePrice = value.toString()
    if(parseInt(namePrice)===0){
        valuePrice = ""
    }else if(namePrice.length>=7 && namePrice.length<=9){
        valuePrice = `${namePrice.slice(0,-6)}-millones`
    }else if(namePrice.length>=4 && namePrice.length<=6){
        valuePrice = `${namePrice.slice(0,-3)}-mil`
    }else if(namePrice.length<=3){
        valuePrice = `${namePrice}-m`
    }

    return valuePrice
}

export const generateFormatURLYear = (value, yearInitial) => {
    let valueYear = ""

    let nameYear = value.toString()
    if(parseInt(nameYear)===parseInt(yearInitial)){
        valueYear = ""
    }else {
        valueYear = `ano-${value}`
    }

    return valueYear
}

export const generateFormatURLKm = (value) => {
    let valueKm = ""

    let nameKm = value.toString()
    if(parseInt(nameKm)===0){
        valueKm = ""
    }else 
    if(nameKm.length>=7){
        valueKm = `${nameKm.slice(0,-6)}-millon-kilometraje`
    }else if(nameKm.length>=4 && nameKm.length<=6){
        valueKm = `${nameKm.slice(0,-3)}-mil-kilometraje`
    }else {
        valueKm = `${nameKm}-kilometraje`
    }

    return valueKm
}

/**Verificar los parámetros obtenido en la url */
export const verifyParamsURL = (arrParamsSearch, params) => {
    
    let arrParamsSearchForm = arrParamsSearch;
	let arrayFountParams = [];
    let arrayNotFountParams = [];

    for(let key in params){

        for(let index in arrParamsSearchForm){

            if(index!=="vTypeRange" && index!=="vTypeYear" && index!=="vTypeKm"){
               
                let searchItem = arrParamsSearchForm[index].findIndex(item => item.slug===params[key])

         
                if(searchItem!==-1){
                    arrParamsSearchForm[index][searchItem].checked = true
                    arrayFountParams.push(params[key])
                    break
                } 

            } else if(index==="vTypeYear" && (params[key].includes("ano-") || params[key].includes("-ano"))) {
                arrParamsSearchForm[index] = formatYearParams(params[key])
                arrayFountParams.push(params[key])
                break

            } else if(index==="vTypeKm" && params[key].includes("kilometraje") && !params[key].includes("p")) {
                arrParamsSearchForm[index] = formatKmParams(params[key])
                arrayFountParams.push(params[key])
                break
            } else if(index==="vTypeRange" &&  !params[key].includes("kilometraje") && (params[key].includes("millones") || params[key].includes("mil"))) {
                arrParamsSearchForm[index] = formatPriceParams(params[key])
                arrayFountParams.push(params[key])      
                break       
            }
        }
    }

    if(params.length !== arrayFountParams.length){
        for(let key in params){
            const verifiedParam = arrayFountParams.find(item => item===params[key])
            if(!verifiedParam){
                let { listBrands, paramFound } = formatModelParams(arrParamsSearchForm["vMarca"], params[key])
                if(paramFound){
                    arrayFountParams.push(params[key])
                }else{
                    arrayNotFountParams.push(params[key])
                }       
                arrParamsSearchForm["vMarca"] = listBrands    
            }            
        }
    }

    let selectedBrands = arrParamsSearchForm["vMarca"]?.filter(item => item.checked===true)
    arrParamsSearchForm["vModelo"] = selectedBrands

    return { arrParamsSearchForm, arrayFountParams }
}

const formatKmParams = (value) => {
    let valueKmGlobal = value.replaceAll("-kilometraje", "")

    if(valueKmGlobal.includes("-hasta-")){
        let valueKm = valueKmGlobal.split("-hasta-")
        const valueKmInital = valueKm[0].includes("mil") 
                                ? parseInt(`${valueKm[0].replace("-mil", "")}000`) 
                                : valueKm[0].includes("millones") 
                                ? parseInt(`${valueKm[0].replace("-millones", "")}000000`)
                                : parseInt(valueKm[0])
        
        const valueKmEnd = valueKm[1].includes("mil") 
                                ? parseInt(`${valueKm[1].replace("-mil", "")}000`) 
                                : valueKm[1].includes("millones") 
                                ? parseInt(`${valueKm[1].replace("-millones", "")}000000`)
                                : parseInt(valueKm[1])
       return { min: parseInt(valueKmInital), max: parseInt(valueKmEnd) }                         
    }else{
        valueKmGlobal = valueKmGlobal.replace("hasta-", "")
        const valueKmInit= valueKmGlobal.includes("mil") 
                                ? parseInt(`${valueKmGlobal.replace("-mil", "")}000`) 
                                : valueKmGlobal.includes("millones") 
                                ? parseInt(`${valueKmGlobal.replace("-millones", "")}000000`)
                                : parseInt(valueKmGlobal.replace("-m", ""))
        
        return { min: 0, max: parseInt(valueKmInit) }   
    }
}

const formatPriceParams = (param) => {

    let valueReturn = { min: null, max: null }
    if(param.includes("-hasta-")){
        let valuePrice = param.split("-hasta-")

        let valueInitial = valuePrice[0].includes("millones") 
            ? `${valuePrice[0].replace("-millones", "000000")}` : `${valuePrice[0].replace("-mil", "000")}`

        let valueEnd = valuePrice[1].includes("millones") 
            ? `${valuePrice[1].replace("-millones", "000000")}` : `${valuePrice[1].replace("-mil", "000")}`

        valueReturn = { min: parseInt(valueInitial), max: parseInt(valueEnd) }
    }else{
        let value = param.includes("millones") 
            ? `${param.replace("hasta-", "").replace("-millones", "000000")}`
            : `${param.replace("hasta-", "").replace("-mil", "000")}`
            valueReturn = { min: 0, max: parseInt(value) }
    }

    return valueReturn
}

const formatYearParams = (param) => {
    let valueYearFormat = { min: null, max: null }

    if(param.includes("-hasta-")){
        let valueYear = param.split("-hasta-")
        let valueYearInitial = valueYear[0].replace("ano-", "")
    
        let valueYearEnd = valueYear[1].replace("ano-", "")
        valueYearFormat = { min: Number(valueYearInitial), max: Number(valueYearEnd) }

    }else if(param.includes("hasta-ano-")){
        valueYearFormat = { min: Number(YEAR_DEFAUTL), max: Number(param.replace("hasta-ano-", "")) }
        
    }else if(param.includes("ano-")){
        valueYearFormat = { min: Number(YEAR_DEFAUTL), max: Number(param.replace("ano-", "")) }
    }

    return valueYearFormat
}

const formatModelParams = (listBrandsSelected, param) => {

    let listBrands = listBrandsSelected
    let paramFound = false

    for(let key in listBrands){
        let modelIndex = listBrands[key].modelos.findIndex(item => item.slug===param)
       if(modelIndex !== -1){
            listBrands[key].modelos[modelIndex].checked = true
            paramFound = true
            break
       }
    }

    return { listBrands, paramFound }
}

export const checkMarkedSearchOptions = (listDataSendForm, listDataSendInital) => {
  
    for (const key in listDataSendForm){

        const index = listDataSendInital.findIndex(item => item.value===listDataSendForm[key].name)
        if(typeof index !=='undefined' && index!==-1){
          listDataSendInital[index].checked = listDataSendForm[key].checked
        }      
    }
  
    return listDataSendInital
}

export const listMarkedSearchOptions = (listDataSendForm, valueDefault) => {

    if(typeof listDataSendForm!=="undefined" && listDataSendForm!==""){
        if(parseInt(listDataSendForm?.min)!==parseInt(valueDefault.min) || parseInt(listDataSendForm?.max)!==parseInt(valueDefault.max)){
            return { min: parseInt(listDataSendForm.min), max: parseInt(listDataSendForm.max) }
        }
    }
    
    return ""
}

export const verifyArrayKmYearRange = (dataInitial, valueDefault) => {

    if(dataInitial.length===0){
        return { min: valueDefault.min, max: valueDefault.max }
    }
    return dataInitial
}

export const getOptionsSearchMarked = (listOptionsSearch, pathname, initialNormalDefault) => {
    let slugEnd = []
    let optionsSendApi = {}
    let itemOptionsFormSend = []
    let titleHeader = ""

    for(let [ key, options ] of Object.entries(listOptionsSearch)){

        optionsSendApi[key] = []
        let itemFormData = []
        if(key==="vTypeRange" && options!==""){

            const { slugPrice, itemFormDataSend } = formatPriceSlug(options)
            slugEnd.push(slugPrice)
            optionsSendApi[key] = options
            itemFormData = itemFormDataSend

        }else if(key==="vTypeYear" && options!==""){
  
            let { slugInput, itemFormDataSend } = formatYearSlug(options, key, initialNormalDefault["vTypeYear"])
            slugEnd.push(slugInput)
            optionsSendApi[key] = options
            itemFormData = itemFormDataSend

        }else if(key==="vTypeKm" && options!==""){
            
            let { slugInput, itemFormDataSend } = formatKmSlug(options, key)
            slugEnd.push(slugInput)
            optionsSendApi[key] = options
            itemFormData = itemFormDataSend

        }else if(key==="vCarroceria" || key==="vCombustible" || key==="vTransmision" || key==="vTypeAuto" || key==="vMarca"
        || key==="vOrigin" || key==="vSeatMove" || key==="vTraction"){
            
            let optionsSelected = options.filter(item => item.checked===true)
            if(optionsSelected.length > 0){
                const { slugItem, itemSelected, itemFormSend } = formatArraySlug(optionsSelected)
                optionsSendApi[key] = itemSelected
                slugEnd = [...slugEnd, ...slugItem ]
                itemFormData = itemFormSend
            }            
        } else if(key==="vModelo"){
            
            let modelos = []
            for(let index in options){
                modelos = [...modelos, ...options[index].modelos]
            }

            let optionsSelected = modelos.filter(item => item.checked===true)

            if(optionsSelected.length > 0){
                const { slugItem, itemSelected, itemFormSend } = formatArraySlug(optionsSelected)
                optionsSendApi[key] = itemSelected
                slugEnd = [...slugEnd, ...slugItem ]
                itemFormData = itemFormSend
            }         
        } else if(key==="vPerformance" ){

            const optionsSelected = options.filter(item => item.checked===true)
            if(optionsSelected.length > 0){
                let { slugInput, itemFormDataSend, valuePerfomanceItem } = formatKmPerfomanceSlug(optionsSelected)
                if(valuePerfomanceItem.length > 0){
                    slugEnd.push(slugInput)
                    optionsSendApi[key] = itemFormDataSend[0]?.value
                    itemFormData = valuePerfomanceItem
                } 
            }
        }
        itemOptionsFormSend = [...itemOptionsFormSend, ...itemFormData ]
    }

    let slug = slugEnd.join("/")

    let metaTags = []
    if(MetaTagUsed.list.length > 0){
        metaTags = [ ...metaTags, ...MetaTagUsed.list ]
    }
    if(MetaTagNew.list.length > 0){
        metaTags = [ ...metaTags, ...MetaTagNew.list ]
    }

    let titleBase = metaTags.find(item => item.urlFriendly===pathname)

    if(titleBase){

        titleHeader = titleBase.titleH1
    }else{
        titleHeader = formtTitleHeader(slugEnd, listOptionsSearch)
    }
 

    return { slug, optionsSendApi, itemOptionsFormSend, titleHeader }
}

const formatPriceSlug = (options) => {
    let slugPrice = ""
  
    const countPrice = options.max.toString().length
  
    if(countPrice < 3){
        slugPrice = `${options.max}`
    }else if(countPrice < 6){
        slugPrice = `${options.max.toString().substr(0,2)}-mil`
    }else if(countPrice === 7){
        slugPrice = `${options.max.toString().substr(0,1)}-millones`
    }else if(countPrice > 7){
        slugPrice = `${options.max.toString().substr(0,2)}-millones`
    }

    let itemFormDataSend = [{
        value: "vTypeRange",
        checked: true,
        name: "vTypeRange",
        title: `${options.min} - ${options.max}`,
        slug: slugPrice
    }]

    return { slugPrice, itemFormDataSend }
}

const formatYearSlug = (options, typeInput, dataInitial) => {
    let minYear = generateFormatURLYear(options.min.toString(), dataInitial.min);
    let maxYear = generateFormatURLYear(options.max.toString(), dataInitial.min);
    let formatNameYear = minYear!=="" ? `${minYear}-hasta-` : ``;
    let slugInput = `${formatNameYear}${maxYear}`;

    let itemFormDataSend = [{
        value: typeInput,
        checked: true,
        name: typeInput,
        title: `${options.min} - ${options.max}`,
        slug: slugInput
    }]    

    return { slugInput, itemFormDataSend }
}

const formatKmSlug = (options, typeInput) => {
    let slugInput = `${options.max}-kilometraje`

    let itemFormDataSend = [{
        value: typeInput,
        checked: true,
        name: typeInput,
        title: `${options.min} - ${options.max}`,
        slug: slugInput
    }]    

    return { slugInput, itemFormDataSend }
}

const formatKmPerfomanceSlug = (valuePerfomanceItem) => {
  
    let vPerformance = ""
    let slugInput = ""
    let itemFormDataSend = []

	if(valuePerfomanceItem.length>0){
		const minium = Math.min.apply(null, valuePerfomanceItem.map(item=>item.valueInt.min))
		const maxium = Math.max.apply(null, valuePerfomanceItem.map(item=>item.valueInt.max))
		vPerformance = { min: minium, max: maxium }
        
        slugInput = `p-${vPerformance.max}-kilometros`

        itemFormDataSend = [{
            value: vPerformance,
            checked: true,
            name: "vPerformance",
            title: `${vPerformance.min} - ${vPerformance.max}`,
            slug: slugInput
        }] 
	}

    return { slugInput, itemFormDataSend, valuePerfomanceItem }
}


const formatArraySlug = (options) => {
    let slugItem = []
    let itemSelected = []
    let itemFormSend = []

    for(const value in options){
        if(options[value].checked===true){
            slugItem.push(options[value].slug) 
            
            let valueForm = options[value].valueForm.split(",")
            itemSelected = [...itemSelected, ...valueForm ]
            itemFormSend = [ ...itemFormSend, options[value] ]
        }
    }
   
    return { slugItem, itemSelected, itemFormSend }
}

export const formtTitleHeader = (slug, listOptionsSearch) => {
    let slugEnd = slug
    
    if(slug.includes("nuevos")){
        let typeCar = slug.shift()
        slugEnd.push(typeCar)
    }    
    let title = "Autos"
    
    for(let index in slugEnd){
        let name = slugEnd[index]
        let isCambio = false

        if(Object.keys(listOptionsSearch).includes("vMarca")){
            const validateMarca = listOptionsSearch["vMarca"].find(item => item.slug===name)
            if(validateMarca){
                name = name.replaceAll("-", " ")
                if(slug.includes("usados") && !slug.includes("nuevos") && slug.length<=3){
                    name = name.toUpperCase()
                }else{
                    name = name[0].toUpperCase() + name.slice(1)
                }
                
                isCambio = true
            }
        }        

        if(isCambio===false){
            
            if(slugEnd[index].includes("-")){
                name = slugEnd[index].replaceAll("-", " ")
    
                if(name.includes("ano")){
                    name = name.replaceAll("ano", "Año") 
                }else if(name.includes("millones")){
                    name = "de " + name.replaceAll("hasta", "").replaceAll("millones", "Millones") 
                }else {
                    const nameSplit = name.split(" ")
    
                    name = nameSplit[0][0].toUpperCase() + nameSplit[0].slice(1) + " "+ nameSplit[1][0].toUpperCase() + nameSplit[1].slice(1)
                }
    
            }else{
                if(name.length===4 && parseInt(name)>2000){
                    name= "Año "+ name
                }else if(name==="suv"){
                    name = name.toUpperCase()
                }else{    
                    name = (name==="automaticos") ? "automáticos" : name
                    name = (name==="espana") ? "España" : name
                    name = name[0].toUpperCase() + name.slice(1)
                }
                
            }  
        }
             
        
 
        title = `${title} ${name}`
    }

    //let title = ("Autos "+slugEnd.map(item => item.replaceAll("-", " ")).join(" ")).toUpperCase()

    return title
}