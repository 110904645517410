import { Modal } from 'react-bootstrap'
import styles from 'styles/concourse.module.css'

const ModalConfirmation = ({ show, handleClose }) => {

	return (
		<Modal show={show} onHide={handleClose} centered dialogClassName={styles["modal-400"]}>
	        <Modal.Body className="subtitle--modal text-center text-dark">
	        Asegúrese que la factura <span className={styles["text-bold"]}>haya sido emitida en una tienda de Movicenter</span>, en caso contrario no podrá participar en la promoción.
	        </Modal.Body>
	        <Modal.Footer className="d-flex justify-content-center">
	          	<button className="btn btn-primary btn-lg w-100" onClick={()=>handleClose()}>
	            	Ok
	          	</button>
	        </Modal.Footer>
	    </Modal>
	)
}

export default ModalConfirmation