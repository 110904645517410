import { Fragment, useEffect } from "react";
import { Switch, Route } from 'react-router-dom'
import Initial from 'presentacions/Home/Initial'
import UsedCar from 'presentacions/Home/UsedCar'
import NewCar from 'presentacions/Home/NewCar'
import MyRoute from 'presentacions/Route/MyRoute'
import CarFile from 'presentacions/Card/CarFile'
import Service from 'presentacions/Service/Service'
import DetalleService from 'presentacions/Service/Detail'
import DetalleProveedor from 'presentacions/Service/DetalleProveedor'
import HomeTire from 'presentacions/Tire/Home'
import CompareCars from 'presentacions/Compare/Cars'
import HomeConcessionaire from 'presentacions/Concessionaire/Home'
import HomeChileCaravan from 'presentacions/Concessionaire/HomeChileCaravan'
import HomeSellYourCar from 'presentacions/SellCar/HomeSellYourCar'
import DetailSellYourCar from 'presentacions/SellCar/DetailSellYourCar'
//import HomeOmniaFitness from 'presentacions/Concessionaire/HomeOmniaFitness'
import NotFound from 'presentacions/NotFound'
import ResultSearchByUrl from 'presentacions/Search/ResultSearchByUrl'

import HomeMotorcycle from 'presentacions/Motorcycle/Home'
import DetailMoto from 'presentacions/Motorcycle/DetailMoto'
import DetailTypeMoto from 'presentacions/Motorcycle/DetailTypeMoto'
import HomeCompara from 'presentacions/Insurance/HomeCompara'

import DetailByStore from 'presentacions/Tire/DetailByStore'
//import HomeConcourseCar from 'presentacions/Concourse/HomeConcourseCar'
import HomeConcourse from 'presentacions/Concourse/HomeConcourse'
//import HomeConcourseTire from 'presentacions/Concourse/HomeConcourseTire'
import HomeCoupon from 'presentacions/Coupon/HomeCoupon'
import HomeSearchByTire from 'presentacions/Tire/HomeSearchByTire'

//import LandingConcourse from 'presentacions/Concourse/LandingConcourse'
//import LandingConcourse1 from 'presentacions/Concourse/LandingConcourse1'
//import HomeElectrical from 'presentacions/Electrical/HomeElectrical'

import Posts from 'presentacions/Blog/Posts'
import SinglePost from 'presentacions/Blog/SinglePost'
import SoapSuccess from 'presentacions/Soap/Success'

import ThankSuccessForm from 'presentacions/ThankSuccessForm'
import HomeAuthorizedStores from 'presentacions/AuthorizedStores/Home'
import DetailAuthorizedStores from 'presentacions/AuthorizedStores/DetailStore'
import HomeInsuranceStore from 'presentacions/Insurance/HomeInsuranceStore';
import useGoogleAnalytics from "routes/useGoogleAnalytics"
import packageJson from "../../package.json";
import { useSecure } from "hooks/useSecure"

import HomeOfertas from 'presentacions/Offer/HomeOfertas'
import LoadingUserScreen from 'components/Layout/LoadingUserScreen';

const Routes = () => {

  useGoogleAnalytics()
  const { getValidateToken, loadingSecure } = useSecure();

  useEffect(() => {
    let version = localStorage.getItem('version-mov');
   
      if(version!==packageJson.version){
          if('caches' in window){
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
          }

        localStorage.clear();
        localStorage.setItem('version-mov',packageJson.version);
      }      
  },[])
  
  useEffect(() => {
		getValidateToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [])



  return (
    <Fragment>
      {
        loadingSecure && (
          <LoadingUserScreen isLoading={loadingSecure} />
        )
      }
      {
        !loadingSecure && (
          <Switch>
            <Route exact path="/">
              <Initial />
            </Route>
            
            <Route exact path="/autos/usados">
              <UsedCar />
            </Route>
            <Route exact path="/autos/nuevos">
              <NewCar />
            </Route>
            <Route exact path="/vehiculos/favoritos">
              <MyRoute />
            </Route>
            <Route exact path="/autos/:key">
              <CarFile />
            </Route>
            <Route exact path="/servicio-automotriz">
              <Service />
            </Route> 
            
            <Route exact path="/compare/cars">
              <CompareCars />
            </Route>
      
            <Route exact path="/servicio-automotriz/neumaticos">
              <HomeTire />
            </Route>
      
            <Route exact path="/servicio-automotriz/neumaticos/busqueda">
              <HomeSearchByTire />
            </Route>
            
            <Route exact path="/servicio-automotriz/neumaticos/:slug">
              <HomeSearchByTire />
            </Route>
      
            <Route exact path="/servicio-automotriz/neumaticos/tienda/:slug">
              <DetailByStore />
            </Route>

            <Route exact path="/vende-tu-auto">
              <HomeSellYourCar />
            </Route>
            <Route exact path="/vende-tu-auto/:slug">
              <DetailSellYourCar />
            </Route>
      
            <Route exact path="/servicio-automotriz/:slug">
              <DetalleService />
            </Route>
            
            <Route exact path="/seguros/seguros-falabella">
              <HomeInsuranceStore />
            </Route>

            <Route exact path="/tienda-servicio-automotriz/:slug">
              <DetalleProveedor />
            </Route>
      
            {/**<Route exact path="/servicio-automotriz/:serviceSlug/:proveedorSlug">
              <DetalleTypeService />
            </Route> */}
      
            <Route exact path="/concesionario/chile-caravan">
              <HomeChileCaravan />
            </Route>
      
          {/**<Route exact path="/tienda/omnia-fitness">
              <HomeOmniaFitness />
            </Route>  */}
      
            <Route exact path="/concesionario/:slug">
              <HomeConcessionaire />
            </Route>
            
            <Route exact path="/blog">
              <Posts />
            </Route>
            <Route exact path='/blog/:slug' >
              <SinglePost />
            </Route> 
      
            <Route exact path='/soap/:insuranceId/success' >
              <SoapSuccess />
            </Route>
      
            <Route exact path='/autos/*'>
              <ResultSearchByUrl />
            </Route>
      
            <Route exact path='/s/autos/nuevos'>
              <ResultSearchByUrl />
            </Route>
      
            <Route exact path='/s/autos/usados'>
              <ResultSearchByUrl />
            </Route>
      
            <Route exact path='/motos'>
              <HomeMotorcycle />
            </Route>
      
            <Route exact path='/motos/:key'>
              <DetailMoto />
            </Route>
            
            <Route exact path='/motos/:type/ficha'>
              <DetailTypeMoto />
            </Route>
      
            <Route exact path='/VkMkMjIj'>
              <HomeCompara />
            </Route>
      
           {/**<Route exact path='/concurso/auto'>
              <HomeConcourseCar />
            </Route>  */}   
      
             <Route exact path='/concurso'>
               <HomeConcourse />
             </Route>   
      
            {/**<Route exact path='/concurso/neumaticos'>
               <HomeConcourseTire />
             </Route> */}
      
             {/** <Route exact path='/concurso'>
              <LandingConcourse1 />
             </Route> 

            {/**<Route exact path='/concurso/auto-regalo'>
              <LandingConcourse />
            </Route>   */}       
      
            <Route exact path='/discount-coupon/c/:token'>
              <HomeCoupon />
            </Route>
      
            {/**<Route exact path='/scooter-y-bicicletas-electricas'>
              <HomeElectrical />
            </Route> */}
      
            <Route exact path='/success'>
              <ThankSuccessForm />
            </Route>
            
            <Route exact path='/movidays'>
              <HomeOfertas />
            </Route>

           <Route exact path='/servicios-autorizados'>
              <HomeAuthorizedStores />
            </Route>

            <Route exact path='/servicios-autorizados/:slug'>
              <DetailAuthorizedStores />
            </Route>
                        
      
            <Route component={NotFound} />
          </Switch>
        )
      }
      
    </Fragment>
  );
}

export default Routes;
