import FormPhoto from "components/Concourse/FormPhoto"
import FormCapturePhoto from "components/Concourse/FormCapturePhoto"
import FormTakePhoto from "components/Concourse/FormTakePhoto"
import ModalConfirmation from "components/Concourse/ModalConfirmation"
import ModalBase from 'components/Concourse/Landing/ModalBase'
//import FormContactMotorcycle from "components/Concourse/FormContactMotorcycle"
import FormContactCar from "components/Concourse/FormContactCar"
import FormResultPage from "components/Concourse/FormResultPage"
import photo_logo_secondary from "assets/images/concourse/logo-bencina.png"
import logo_movi_days from "assets/images/concourse/logo-movidays.png"

import PanelCardLogo from 'components/Concourse/Custom/PanelCardLogo'
import styles from 'styles/concourse.module.css'
import { useConcourse } from "hooks/Concourse/useConcourse"

const HomeConcourse = () => {

	const { messageConcourse, nextFormNameConcourse, videoPhoto, canvasPhoto, 
		screenshotImage, handleTakePhoto, handleCapturePhoto, btnTakePhotoDsk, 
		btnTakePhotoMobile, handleResetPhoto, handleNextContact, handleChangeTakePhoto, 
		errMsg, listDevices, panelTakePhotoSelected, panelTakePhotoPreview, 
		handleResetCapturePhoto, handleChangeSelected, gotToHome, backValidationPhoto,
		showModal, handleCloseModal, handleModalPoliticas, showPoliticas } = useConcourse(true)

	return (
		<>
			<PanelCardLogo
				nextFormNameConcourse={nextFormNameConcourse}
				title="¡Llévate una moto Yamaha Cygnus Ray zr!"
				type="moto"
				subtitle="Por la compra de servicios de repuestos y accesorios"
				gitVehicle={photo_logo_secondary}
				classGitVehicle="image-container__moto"
				classTitle="title-moto"
				classSubtitle="subtitle-moto"
				handleModalPoliticas={handleModalPoliticas}
				gitLogoMain={logo_movi_days}
			>
				
				{nextFormNameConcourse==="form_initial" && 
		        	<FormPhoto  
		        		handleTakePhoto={handleTakePhoto}
		        		handleChangeTakePhoto={handleChangeTakePhoto}
		        		btnTakePhotoMobile={btnTakePhotoMobile}
		        		btnTakePhotoDsk={btnTakePhotoDsk}
		        		title="moto"
		        		info_1="Sube la factura de tu compra que corresponda al mes de Agosto para participar."
		        		info_3="Ya estás participado por un año de bencina."
		        	/>
		       	}
		       	{nextFormNameConcourse==="form_capture" && 
		       		<FormCapturePhoto
		       			videoPhoto={videoPhoto} 
		        		canvasPhoto={canvasPhoto} 
		        		handleCapturePhoto={handleCapturePhoto}
		        		listDevices={listDevices}
		        		screenshotImage={screenshotImage}
		        		panelTakePhotoSelected={panelTakePhotoSelected}
		        		panelTakePhotoPreview={panelTakePhotoPreview}
		        		handleResetPhoto={handleResetPhoto}
		        		handleNextContact={handleNextContact}
		        		handleResetCapturePhoto={handleResetCapturePhoto}
		        		handleChangeSelected={handleChangeSelected}
		        		title="moto"
		        		info_1="Sube la factura de tu compra que corresponda al mes de Agosto para participar."
		       		/>
		       	}
		       	{nextFormNameConcourse==="form_photo" && 
		        	<FormTakePhoto 
		        		screenshotImage={screenshotImage}
		        		panelTakePhotoPreview={panelTakePhotoPreview}
		        		handleResetPhoto={handleResetPhoto}
		        		handleNextContact={handleNextContact}
		        		info_1="Sube la factura de tu compra que corresponda al mes de Agosto para participar."
		        	/>
		       	}
		       	{nextFormNameConcourse==="form_contact" && 
					<FormContactCar 
		        		info_1="Sube la factura de tu compra que corresponda al mes de Agosto para participar."
		        		gotToNextForm={backValidationPhoto} 
		        	/>
		       	}
		       	{nextFormNameConcourse==="form_end" && 
		        	<FormResultPage 
		        		message={messageConcourse}
		        		gotToNextForm={gotToHome} 
		        	/>
		       	}
		       	{
		       		errMsg.status && (
		       			<div className={styles["error-container"]}>
				       		<p className={styles["error-container__description"]}>{errMsg.message}</p>
				       	</div>
		       		)
		       	}				       	
			</PanelCardLogo>
			{ showModal && <ModalConfirmation show={showModal} handleClose={handleCloseModal} />}
			{showPoliticas && <ModalBase show={showPoliticas} handleClose={handleModalPoliticas} />}
		</>
	)
}

export default HomeConcourse
