import { useEffect } from "react"
import BaseLayoutStore from 'components/Layout/BaseLayoutStore'
import PanelSearchMeasure from 'components/Services/PanelTire/PanelSearchMeasure'
import FormContactTire from 'components/Customs/Contact/FormContactTire'
import MapaAuto from 'components/Home/Used/MapaAuto'
import ListItemService from 'components/Services/ResultSearchTire/ListItemService'
import ModalSpinnerTire from 'components/Customs/Modal/ModalSpinnerTire'
import ButtomQuote from 'components/Services/ResultSearchTire/ButtomQuote'

import services_and_accessories from 'assets/images/services_and_accessories.svg'

import { useParams, useLocation } from 'react-router-dom'
import { homeTire } from 'data/DescripcionHead.json'

import { useSearchByStore } from "hooks/Tires/useSearchByStore"
import { useSearchTire } from "hooks/Tires/useSearchTire"


const DetailByStore = () => {


	const { slug } = useParams()
	const location = useLocation()

	const { state } = useSearchByStore(slug)
	const { state: stateSearch, actions: actionsSearch } = useSearchTire()

	const { measures,  tireSelectedQuote , resultTires, detailConcessionare} = state 
	const { valueSearchMeasure } = stateSearch

	const { getSearchInitial, handleMoveTo } = actionsSearch

	useEffect(() => {
		if(Object.keys(detailConcessionare).length>0){
			getSearchInitial(location, detailConcessionare?.name)
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueSearchMeasure?.search])

	return (
		<BaseLayoutStore
			infoMetaHead={homeTire}
			infoHeader={detailConcessionare} 
			classStyle={{
				classTitle: "header-separator"
			}}
			handleMoveTo={handleMoveTo}
		>
			<div className="row">
				<div className="col-12 mt-5">
					{Object.keys(measures)?.length>0 &&  
						<PanelSearchMeasure 
							listWidth={measures?.listWidth}
							listProfile={measures?.listProfile}
							listRing={measures?.listRing}
							isLoading={false}
							isFilterByMarca={true} 
						/>
					}
				</div>
			</div>

			{resultTires?.list?.length>0 && <ListItemService list={resultTires?.list} />}
			
			<MapaAuto 
				imagen={services_and_accessories}
				title="Derco"

			>
				<p className="panel__title panel__title--service mt-5">Dónde puedo encontrarlo</p>
			</MapaAuto>

			<FormContactTire 
				isVisibleVIN={true} 
				classForm="service-form-contact service-form-contact--white" 
				classRow="gray-form-contact"
				infoSendItem={tireSelectedQuote}
				provider={detailConcessionare?.name}
			/>

			{tireSelectedQuote?.length>0 && 
				<ButtomQuote count={tireSelectedQuote?.length} />}
			
			{
				resultTires.isLoading &&
				<ModalSpinnerTire 
						message="Espera un momento, estamos buscando tus neumáticos" 
					/>
			}

		</BaseLayoutStore>
	)
}

export default DetailByStore