import { Modal } from 'react-bootstrap'
import styles from 'styles/concourse.module.css'
import close_img from 'assets/images/icons/delete-modal.svg'
//import pdf_base from 'pdf/BASES_PROMOCIÓN_MOTOQUIERO_EN_MOVICENTER.pdf'

const ModalBase = ({ show, handleClose }) => {

	return (
	    <Modal 
	    	show={show} 
	    	onHide={(event)=>handleClose(event)} 
	    	centered size="xl" 
	    	aria-labelledby="contained-modal-title-vcenter" 
	    	backdrop="static"
        	keyboard={false}
	    >
	        <Modal.Body>
	        	<div className={`text-right btn-close-modal ${styles["btn-close__icon"]}`} onClick={(event)=>handleClose(event)}>
	        		<img src={close_img} alt="Cerrar" />
	        	</div>
	        	<div className="embed-responsive embed-responsive-16by9">
				  <iframe 
				  	src={`https://movicenter-web.s3.us-east-2.amazonaws.com/bases-concursos/BASES+PROMOCIO%CC%81N+1+AN%CC%83O+DE+BENCINA+GRATIS+(MOVIDAYS+AGO+2024)_V02.pdf`}
				  	title="Bases del Concurso" 
				  	className="embed-responsive-item" 
				  	width="100%" 
				  	>
				  </iframe>
				</div>
	        </Modal.Body>
	    </Modal>
	)
}

export default ModalBase