import { listMeta } from "hooks/MetaHead/ListMetaConcessionaire.json"
import { listMetaServices } from "hooks/MetaHead/ListMetaService.json"
import { listMetaTire } from "hooks/MetaHead/ListMetaTire.json"
import MetaTagNew from "hooks/MetaHead/MetaTagNew.json"
import MetaTagUsed from "hooks/MetaHead/MetaTagUsed.json"

export const useConfig = (url) => {
		
    const generateMetaHeadGlobal = (url) => {

        if(url!==""){

            let urlVerfied = listMeta.find(item => item.urlFriendly===url)

            if(urlVerfied){
                return urlVerfied
            }
        }

        return {
            urlFriendly: "",
            title: "Concesionario de Automóviles Nuevos en Santiago, Chile | Movicenter",
            description: "Conoce acá nuestro Concesionario de Automóviles Nuevos ubicado en Santiago, Chile. Aquí encontrarás las mejores marcas y modelos."
        }
    }

    const generateMetaServiceGlobal = (url) => {
        
        if(url!==""){

            let urlVerfied = listMetaServices.find(item => item.urlFriendly===url)

            if(urlVerfied){
                return urlVerfied
            }
        }

        return {
            urlFriendly: "",
            title: "Servicio Mecánico Automotriz | Movicenter",
            description: "Conoce acá nuestro Servicio Mecánico Automotriz con accesorios, lavado, repuestos, revisión de K.M y mucho más. Tenemos todo lo que necesitas ¡Visítanos!"
        }
    }

    const generateMetaTireGlobal = (url) => {
        
        if(url!==""){

            let urlVerfied = listMetaTire.find(item => item.urlFriendly===url)

            if(urlVerfied){
                return urlVerfied
            }
        }

        return {
            urlFriendly: "",
            title: "Servicio Mecánico Automotriz | Movicenter",
            description: "Conoce acá nuestro Servicio Mecánico Automotriz con accesorios, lavado, repuestos, revisión de K.M y mucho más. Tenemos todo lo que necesitas ¡Visítanos!"
        }
    }

    const generateMetaTagMain = (url, params) => {
        let infoMetaTag = {
            "conditional": true,
            "urlFriendly": "default",
            "title": "Venta de Autos Nuevos y usuados en Chile ¡Revisa modelos y precios! | Movicenter",
            "description": "Conoce acá la Venta de Autos Nuevos y usuados en Chile. Revisa aquí sus distintos modelos a los mejores precios ¡Visita nuestro concesionario y haz tu reserva ahora!"
        }

        try{
            
            if(url!==""){
                let metaTags = []
                if(MetaTagUsed.list.length > 0){
                    metaTags = [ ...metaTags, ...MetaTagUsed.list ]
                }
                if(MetaTagNew.list.length > 0){
                    metaTags = [ ...metaTags, ...MetaTagNew.list ]
                }
                
                infoMetaTag = metaTags.find(item => item.urlFriendly===url)
            }
    
            return infoMetaTag
        }catch(err){
            return infoMetaTag
        }        
    }

	return {
        generateMetaHeadGlobal,
        generateMetaServiceGlobal,
        generateMetaTireGlobal,
        generateMetaTagMain
	}
}