//import send_email from '../../assets/images/icon/send-mail.svg'
//import error_send_email from '../../assets/images/icon/error_send_email.png'
import styles from 'styles/concourse.module.css'

const FormResultPage = ({ message, gotToNextForm }) => {

	return (
		<section className="mt-5">
	        <div className="notification-text">
	            <h1 className={styles["notification-text__title"]}>{message.title}</h1>
	            <p className={styles["notification-text__subtitle"]}>
	              {message.subtitle}
	            </p>
	        </div>
	        <div className="w-100 mt-5 mb-4">
	        	<button 
	          		type="button" 
	          		className={`btn btn-primary ${styles["btn-next"]}`} 
	          		onClick={()=>gotToNextForm(0)}
	        		>
	        		{message.btnTitle}
	        	</button>
	      	</div>
	     </section>
	)
}

export default FormResultPage