import CaptureValidatePhoto from 'components/Concourse/Custom/CaptureValidatePhoto'
/**import icon_1 from "assets/images/concourse/1.svg"
import icon_2 from "assets/images/concourse/2.svg"**/
import styles from 'styles/concourse.module.css'



const FormTakePhoto = ({ screenshotImage, panelTakePhotoPreview, 
	handleResetPhoto, handleNextContact, title, info_1 }) => {
	
	return (
		<>
			<div className={styles["list-item"]}>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>1</p>
					</div>
				    <p className={styles["item-description"]}>{info_1}</p>
				</div>
				<div className={styles["item"]}>
					<div className={styles["item-image"]}>
						<p>2</p>
					</div>
				    <p className={styles["item-description"]}>Asegura que en la fotografía, la factura aparezca completa y sea legible.</p>
				</div>
			</div>
		    <CaptureValidatePhoto
		    	panelPreview={panelTakePhotoPreview} 
		    	screenshotImage={screenshotImage} 
		    	handleResetPhoto={handleResetPhoto} 
		    	handleNextContact={handleNextContact}
		    	indexForm={0}
		    	createdPhoto={false}
		    />
	    </>
	)
}

export default FormTakePhoto