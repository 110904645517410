import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { nextPrevForm, saveFormDataConcourseCar, saveFormDataGlobal, resetInfoInitial } from "services/actions/concourseAction"
import { validarRut } from "utils/rut"

import { useDispatch, useSelector } from 'react-redux'
import { phone } from "phone";

export const useFormContactCar = () => {

	const dispatch = useDispatch()
	const { dataTypeConcourse } = useSelector((state) => state.concourses)
	const [ proccessForm, setProcessForm ] = useState({ loading: false, message: "", status: true})
	const [ count, setCount] = useState(0)

	const { register, control, handleSubmit, errors, setError, setValue } = useForm({mode: "onChange", defaultValues: {
        phone: dataTypeConcourse.phone,
        email: dataTypeConcourse.email,
        rut: dataTypeConcourse.rut,
        surname: dataTypeConcourse.surname,
        name: dataTypeConcourse.name,
        invoice_number: dataTypeConcourse.invoice_number
    }})


    function startAnimation() {

    	let value = 0

	    let timerId = setInterval(() => {
	    	value = value + 1
	    	if(value<80){
	    		setCount(count => value)
	    	}else{
	    		clearInterval(timerId)
	    	}
	  }, 1000);
	}

	const onSubmit = async (data) => {		
		try {
			
			if(proccessForm.status===true){
				setProcessForm({ loading: false, message: `Debe aceptar los términos y condiciones`, status: true})
            	return false
			}

			setProcessForm({ loading: true, message: "", status: true })
			
			startAnimation()

			const responseRut = validarRut(data.rut)
			if(responseRut?.status===false){
				setError("rut", { type: "manual", message: responseRut?.message})
				setProcessForm({ loading: false, message: responseRut?.message, status: false })
				return false
			}else{
				setError("rut", { type: "manual", message: ""})
			}

			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(data.email)
            if(!isValidEmail){
                setValue("email", "")
				setProcessForm({ loading: false, message: `El e-mail ${data.email} es inválido`, status: false})
            	return false
            }

            if(data.phone.length<9){
				setError("phone", { type: "manual", message: `El número ingresado es incorrecto`})
				setProcessForm({ loading: false, message: "El número ingresado es incorrecto", status: false })
            	return false
            }

			regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
			const isValidTelephone = regex.test("+56"+data.phone)
            if(!isValidTelephone){
                setValue("phone", "")
				setProcessForm({ loading: false, message: `El teléfono +56 ${data.phone} es inválido`, status: false})
            	return false
            }

            let rut_invoice = `${data.rut}${data.invoice_number}`

			let response = await dispatch(saveFormDataConcourseCar({
				...data,
				email: data.email.toLowerCase(),
				phone: "+56"+data.phone,
				rut_invoice
			}))
			
			setProcessForm({ loading: false, message: response.message, status: false})
			setCount(0)
			clearInterval(startAnimation)
			if(response.status){
				dispatch(resetInfoInitial("form_end"))
				dispatch(nextPrevForm(4))	
				window.scrollTo(0, 0)
			}else{
				setProcessForm({ loading: false, message: "El RUT o el número de factura ya se encuentra registrado", status:false })
				setCount(0)
				clearInterval(startAnimation)
			}
		}catch(error){
			setProcessForm({ loading: false, message: "Hubo un error al enviar los datos", status:false })
			setCount(0)
			clearInterval(startAnimation)
		}
	}

	const handleBlurRut = (event) =>{
		if(event.target.value!==""){
			const response = validarRut(event.target.value)
			if(response?.status===false){
				setError("rut", { type: "manual", message: response?.message})
			}else{
				setError("rut", { type: "manual", message: ""})
			}
		}		
	}


	const onChangeInput = (event, type, name) => {
		const { value } = event.target
		dispatch(saveFormDataGlobal({ [name]: value!=="" ? value : ""}))
	}

	const handleChangePhoneNumber = (event) => {

		if(event.target.value!==""){
			if(event.target.value.length===9){
				let regex=/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
				const isValidTelephone = regex.test("+56"+event.target.value)

				if(!isValidTelephone){
					setValue("phone", "")
					setError("phone", { type: "manual", message: `El teléfono es inválido`})
				}else {
					setError("phone", { type: "manual", message: ""})
					if (!phone(`+56${event.target.value}`, {country: 'CL'}).isValid) {
						setError("phone", { type: "manual", message: `El teléfono es inválido`})
					}
				}
			}else{
				setError("phone", { type: "manual", message: `El número ingresado es incorrecto`})
			}
		}		
	}

	const handleChangeEmail = (event) => {
		if(event.target.value!==""){
			let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
			const isValidEmail = regex.test(event.target.value)

			if(!isValidEmail){
				setValue("email", "")
				setError("email", { type: "manual", message: `El e-mail es inválido`})
			}else {
				setError("email", { type: "manual", message: ""})

				const partEmail = event.target.value.split("@");

				if(partEmail?.length===2){
					const regex = /^.{3,}$/;

					if (!regex.test(partEmail[1])) {
						setError("email", { type: "manual", message: `El e-mail es inválido`})
					}
			
					if (!regex.test(partEmail[0])) {
						setError("email", { type: "manual", message: `El e-mail es inválido`})
					}
			
					const partDomain = partEmail[1]?.split(".");
					if(partDomain?.length === 2){
						if (!regex.test(partDomain[0])) {
							setError("email", { type: "manual", message: `El e-mail es inválido`})
						}
					}    
				}

				
			}
		}	
	}

	const handleActive = (event) => {
		setProcessForm({ loading: false, message: "", status: !event.target.checked})
	}

	const validatePhone = (value) => {
		if (!phone(`+56${value}`, {country: 'CL'}).isValid) {
            return "El teléfono es inválido";
        }
	};

	const validateMail = (value) => {

        const partEmail = value.split("@");

        if(partEmail?.length===2){
            const regex = /^.{3,}$/;

            if (!regex.test(partEmail[1])) {
                return 'El e-mail es inválido';
            }
    
            if (!regex.test(partEmail[0])) {
                return 'El e-mail es inválido';
            }
    
            const partDomain = partEmail[1]?.split(".");
            if(partDomain?.length === 2){
                if (!regex.test(partDomain[0])) {
                    return 'El e-mail es inválido';
                }
            }    
        }
    }


	return {
		register,
		onSubmit,
		handleSubmit,
        control,
		errors,
		handleBlurRut,
		onChangeInput,
		handleChangePhoneNumber,
		handleChangeEmail,
		dataTypeConcourse,
		proccessForm,
		handleActive,
		count,
		validatePhone,
		validateMail
	}
}