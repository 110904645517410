import { useEffect } from "react"
import BaseLayoutTire from 'components/Layout/BaseLayoutTire'
import SliderMarca from 'components/Services/CarouselTire/SliderMarca'
import StoreResult from 'components/Services/ResultSearchService/StoreResult'
import { homeTire } from 'data/DescripcionHead.json'
import { useSearchTire } from "hooks/Tires/useSearchTire"

const Home = () => {

	const { state, actions } = useSearchTire()

	const { listTires, resultServices, advertisingTire  } = state 

	const { cleanSearchValue, actionAdvertising } = actions

	useEffect(() => {
		cleanSearchValue()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<BaseLayoutTire
			infoMetaHead={homeTire}
			title="Distintas Medidas y Marcas de Neumáticos"
		>
			<SliderMarca listado={listTires} />

			<StoreResult 
				advertisingTire={advertisingTire} 
				resultServices={resultServices} 
				actionAdvertising={actionAdvertising}
				lastPage={{
					prevPath: "/servicio-automotriz/neumaticos",
					name: "Neumáticos"
				}}
			/>
			
		</BaseLayoutTire>
	)
}

export default Home