import { useEffect, useState, useRef } from 'react'
import Layout from 'components/Layout/Layout'
import Breadcrumb from 'components/Customs/Breadcrumb/index'
import FormContactGlobal from 'components/Customs/Contact/FormContactGlobal'
import Features from 'components/CarFile/Information/Features'
import MapFileCar from 'components/Customs/Mapa/MapFileCar'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'
import BannerUpRight from 'components/Customs/Banner/Vertical/BannerUpRight'
import Services from 'components/CarFile/Services/Services'
import Featured from 'components/Customs/Featured/Featured'
import CarouselThumb from 'components/CarFile/CarouselThumb'
import Blog from 'components/Blog/Blog'
import Cards from 'components/Customs/Panels/Cards'
import PanelInformation from 'components/Customs/CardVehicle/PanelInformation'
import ModalSpinner from 'components/Customs/Modal/ModalSpinner'

import MediaQuery from 'react-responsive'
//import ButtonInterested from '../../components/Details/Car/ButtonInterested'
import { getRecommendedCars, getDetailsCar } from 'services/actions/carActions'
import { getListNews } from 'services/actions/blogActions'
import { useFeatured } from 'hooks/useFeatured'
import { useAdvertising } from 'hooks/useAdvertising'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { cardCarInfo } from "data/DescripcionHead.json"
import useBreadcrumb from "hooks/Main/useBreadcrumb"


const CarFile = () => {

	const { key } = useParams()
	const dispatch = useDispatch()	
	const history = useHistory()
	const mapVector = useRef();
	const { advertisingCars, actionAdvertising, getAdvertisingInitial } = useAdvertising()
	const { getLoadProvider, categoryProviders } = useFeatured()
	const { getInfoBreadcrumb, itemsBreadcrumb , pageLast} = useBreadcrumb()

	const [ isLoading, setIsLoading ] = useState(false)
	const { cars, blog  } = useSelector((state) => {
		return {
			cars: state.cars,
			blog: state.blog
		}
	})

	const { infoCar, listRecommendedCars  } = cars
	const { news, loadingPost } = blog

	
	const getSearching = async () => {
		
		setIsLoading(true)
		await getAdvertisingInitial()
		
		if(categoryProviders.length===0){
			await getLoadProvider('', false)
		}
		const response = await dispatch(getDetailsCar(key))
		
		setIsLoading(false)	
		if(response.status){
			
			await dispatch(getRecommendedCars(response.typeRecommended))
		}	
		
		if(news.length===0){
			await dispatch(getListNews())
		}
	}

	const goToConcesionaire = (slug) => {
		history.push(`/concesionario/${slug}`)
	}

	const goToSelectCar = (key) => {
		history.push(`/autos/${key}`)
	}

	useEffect(() => {
		getSearching()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key])

	//Funcionalidad del menú miga de pan
	useEffect(() => {
		if(Object.keys(infoCar).length>0 && infoCar?.name!=="undefined" && infoCar?.name!==""){
			getInfoBreadcrumb(infoCar?.name)
		}		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [infoCar?.name])

	return (
		<>
		<Layout
			classHeader="row header__white"
			hiddenColor={true}
			isVisibleFooter={true}
			visibleLine={true}
			infoMetaHead={cardCarInfo}
			classDefaultHeader="row header-top header__white"
			classTopHeader=""
		>
			<Breadcrumb 
				itemBreadcrumb={itemsBreadcrumb}
				isVisible={true}
				urlReturn={pageLast}
			/>
			
			<>
			{
				(infoCar && Object.keys(infoCar).length>0 && infoCar?.id!=="") && (
					<>


							
							<div className="container">
								<h1 className="page-car__title">{infoCar?.name}</h1>
							</div>
							
							<div className="container page-car__section_1">
								<div className="page-car__information">
									
									<CarouselThumb 
										photos={infoCar?.photos}
										id={infoCar?.id}
										isMyRouter={infoCar?.isMyRouter}
										idKey={infoCar?.key}
										title={infoCar?.name}
										type="autos"
									/>
								
									<Cards 
										classContainer="border-0 panel-detail__car" 
										classBody="padding-body"
									>
										<PanelInformation 
											type={infoCar?.type}
											name={infoCar?.name}
											price={infoCar?.price}
											infoBrand={infoCar?.infoBrand}
											isVisibleShare={true}
											mileage={infoCar?.mileage} 
											transmission={infoCar?.transmission} 
											fuel={infoCar?.fuel}
											classMain="info-car__description"
											id={infoCar?.id}
											typeKey={infoCar?.typeKey}
											listPrice={infoCar?.listPrice}
											priceLockDown={infoCar?.priceLockDown}
											listPriceView={infoCar?.listPriceView}
											goToConcesionaire={goToConcesionaire}
											isMyRouter={infoCar?.isMyRouter}
											idKey={infoCar?.key}
											viewCar={goToSelectCar}
											status_id={infoCar?.status_id}
											stock={infoCar?.stock}
										/>
									</Cards>

									<MediaQuery minWidth={768}>
										<div className="row">
											<div className="col-12">
												<div className="mt-5 page-car__section_2">
													<Features 
														{...infoCar}
													/>
												</div>
											</div>
										</div>
									</MediaQuery>
								</div>
								<div>
										<FormContactGlobal 
											infoSendItem={infoCar}
											styleForm={{
						        				title: "form-custom__title",
						        				group: "form-car__group",
						        				input: "form-car__input",
						        				labelCheck: "form-car--label",
						        				groupBtn: "group-contact__car",
						        				main: "form-contact__car"
						        			}}
						        			//valueBtnSend={infoCar?.status_id!==1 ? true : false}
											stock={infoCar?.stock}
										/>
										{advertisingCars.length>0 &&
										<>
											<BannerEvent 
												imagen={advertisingCars[0]}
												functionModal={actionAdvertising}
												title="Banner" 
												classContainer="mb-0 mb-md-3 quote-seller__img aspRatio" 
												classPicture=""
											/>
											<BannerEvent 
												imagen={advertisingCars[1]}
												functionModal={actionAdvertising}
												title="Banner" 
												classContainer="mb-0 mb-md-3 quote-seller__img aspRatio" 
												classPicture=""
											/>
											<BannerEvent 
												imagen={advertisingCars[2]}
												functionModal={actionAdvertising}
												title="Banner" 
												classContainer="mb-0 mb-md-3 quote-seller__img" 
												classPicture=""
											/>
										</>
									}
									
									<MediaQuery minWidth={768}>
										<MapFileCar localMap={infoCar?.localMap?.localMap} mapVector={mapVector} />
										{advertisingCars.length>0 &&
											 <BannerUpRight 
											 	imagen={advertisingCars[3]}
												title="Banner"
												functionModal={actionAdvertising}
											/>
										}
									</MediaQuery>
								</div>
							</div>


						<div className="row">
							<div className="col-12">
								<MediaQuery maxWidth={768}>
									<div className="container page-car__section_2">
										<Features 
											{...infoCar}
										/>
										<div className="mapa-row__container">
											<MapFileCar localMap={infoCar?.localMap?.localMap} mapVector={mapVector} />
											
										</div>
									</div>
							</MediaQuery>
							</div>
						</div>

						<Services functionModal={actionAdvertising} />

						{advertisingCars.length>0 &&
							<BannerEvent 
								imagen={advertisingCars[4]}
								functionModal={actionAdvertising}
								title="Banner" 
								classContainer="car-banner" 
								classPicture="car-banner__detail"
							/>
						}

						 <Featured
							classContainer = "tag_container--car"
							title="Recomendaciones"
							SlideClass="card__feature--default"
							classFeatured="col_featured--default"
							listado={listRecommendedCars?.list}
							classSlide="featured featured--default"
							visibleNavigation={false}
						 />

						 <Blog 
						 	title="Conoce nuestro" 
							subtitle="Blog" 
							list={news} 
							isLoop={true} 
							loading={loadingPost} 
							classCarousel="services-blog"
							titleButton="Ir al blog"
						/>
					</>
				)
			}
			</>
			{/**<ButtonInterested />**/}
			{isLoading && 
				<ModalSpinner message="Espera un momento, estamos generando la información" /> }

		</Layout>
		</>
	)
}

export default CarFile
