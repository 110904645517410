import Layout from 'components/Layout/Layout'
import SliderImage from 'components/Home/SliderImage'
import SliderImageLoading from 'components/Home/SliderImageLoading'
import Featured from 'components/Customs/Featured/Featured'
import Services from 'components/Customs/Featured/Services'
import ServicesOther from 'components/Customs/Featured/ServicesOther'
import Catalogy from 'components/Customs/CatalogyBrand/Catalogy'
import BannerEvent from 'components/Customs/Banner/Horizontal/BannerEvent'

import TagAndDiscover from 'components/Home/Initial/TagAndDiscover'
import Blog from 'components/Blog/Blog'
//import SimpleSearch from 'components/Home/Initial/SimpleSearch'
import Search from 'components/Searching/Search'
import ModalInitial from 'components/Home/Initial/ModalInitial'
import Ubication from 'components/Home/Initial/Ubication'
import MarqueUrlFriendly from 'components/Home/MarqueUrlFriendly'
import SectionMotorcycle from 'components/Home/Initial/SectionMotorcycle'

import { homeInitialCar } from 'data/DescripcionHead.json'
import { useHome } from 'hooks/Main/useHome';

const Initial = ()  => {

	const { state, routePathCurrent, actions } = useHome("car-home", "car-new")
	
	const { advertisingHomeMain, listSuppliers, listFeaturedCars, listServicesHome, 
		listServices, listTypeMotorcycle, isOpen, news, listPostNews, loadingPost, groupListStore, metaTags,
		advertisingSliderPrincipal, pageDurationTimerPrincipal, loadingSlider } = state

	const { actionAdvertising, closeModal, handleClickModal } = actions


	return (
		<>
			<Layout
				classHeader=""
				hiddenColor={false}
				isVisibleFooter={true}
				visibleLine={true}
				infoMetaHead={homeInitialCar}
				classDefaultHeader="row header-top"
				classTopHeader="header__white"
				classMenuHeader="background-top-white"
				typePage={true}
			>
				{
					loadingSlider ? (
						<SliderImageLoading />
					) : (
						<SliderImage 
							banners={advertisingSliderPrincipal} 
							functionModal={actionAdvertising} 
							options= {{
								className: "slider-header-home",
								navigation: false,
								pagination: advertisingSliderPrincipal.length===1 ? false : true,
								spaceBetween: 0,
								autoplay: {
									delay: advertisingSliderPrincipal.length===1 ? null : typeof pageDurationTimerPrincipal !=="undefined" ? pageDurationTimerPrincipal : 3000,
								},
								loop: advertisingSliderPrincipal.length===1 ? false : true,
								speed: advertisingSliderPrincipal.length===1 ? null  : 6000,
							}} 
						/>
					)
				}
				
			<Search 
					isClass="card-color" 
					closeVisible={true} 
					isVisibleTypeAuto={true} 
					isVisibleSearch={true}
					typeHomeSelected={"car-new"}
					routePathCurrent={routePathCurrent}
					/>
			
			  { (advertisingHomeMain?.length>0 && advertisingHomeMain[1]?.is_banner_hidden===false) && (
				<BannerEvent 
					imagen={advertisingHomeMain[1]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="car-banner__detail banner-main--home"
				/>
			)}

			<Catalogy
				titleFilter="Filtrar por marca"
				listado={listSuppliers}
				isClassTitle="catalogy__marca--default"
				isPagination={true}
				classCarousel="carousel__new"
				lastPage={{
					prevPath: "",
					name: ""
				}}
				classCol="col-12"
				classContainer="container"
				groupList={groupListStore}
			>
				<div className="catoly-title">
					<p className={`panel__title`}>Conoce las marcas que están en <span className="panel__title--orange">Movicenter</span></p>
				</div>
			</Catalogy>

			<Blog 
				key={2} 
				classCarousel="blog-news" 
				title="Conoce nuestras" 
				subtitle="últimas novedades" 
				list={listPostNews} 
				isLoop={false} 
				isDescriptionView={false}
				loading={loadingPost} 
				titleButton="Ir a novedades"
			/>

			<Featured
				classContainer="tag_container--default"
				title="Destacados"
				SlideClass="card__feature--default"
				classFeatured="bg-gray pt-5"
				listado={listFeaturedCars?.list}
				classSlide="featured featured--default"
				visibleNavigation={true}
				lastPage={{
					prevPath: "",
					name: ""
				}}
			 />

			{ listServicesHome && 
				<Services 
					title="Una ciudad pensada en los"
					subtitle=" automóviles"
					listado={listServicesHome}
					classColor="bg-gray city_car--default"
					classContainer="service__container--default"
					classTitle="container__interest__title--default"
				/>
			}

			{listServices?.secondary?.length>0 && 
				<div>
					<ServicesOther 
						title="La entretención también esta en Movicenter"
						listado={listServices?.secondary}
						classColor="container__interest--other"
						classContainer="service__container--default service__container--other"
						classTitle="container__interest__title--other"
					/>
				</div>	
			}

			<SectionMotorcycle listTypeMotorcycle={listTypeMotorcycle} />

			{ advertisingHomeMain?.length>0 && (
				<BannerEvent 
					imagen={advertisingHomeMain[2]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="car-banner__detail banner-main--home"
				/>
			)}
		
			<div className="row">
				<div className="col-12">
					<div className="container">
						<p className="panel__title panel__title--align pt-5">Todo esto y mucho más, solo en <span className="text--orange">Movicenter</span></p>
					</div>
				</div>
				<div className="col-12 banner-home__zindex">
					{ advertisingHomeMain?.length>0 && (
						<BannerEvent 
							imagen={advertisingHomeMain[3]}
							functionModal={actionAdvertising}
							title="Banner" 
							classContainer="mb-0" 
							classPicture="banner-home__position_4"
						/>
					)}
				</div>
			</div>
			

			<div className="row">
				<div className="col-12">
					{ advertisingHomeMain?.length>0 && (
						<BannerEvent 
							imagen={advertisingHomeMain[4]}
							functionModal={actionAdvertising}
							title="Banner" 
							classContainer="banner__financing" 
							classPicture="banner__top_mobile--35"
						/>
					)}
				</div>
			</div>

			<TagAndDiscover banners={advertisingHomeMain} actionAdvertising={actionAdvertising} />
			
			{ advertisingHomeMain?.length>0 && (
				<BannerEvent 
					imagen={advertisingHomeMain[7]}
					functionModal={actionAdvertising}
					title="Banner" 
					classContainer="mb-0" 
					classPicture="banner__top_mobile--35 banner__top_mobile--6"
				/>
			)}
					
			{ metaTags.length>0 && <MarqueUrlFriendly  url_friendly={metaTags} />}

			<Blog 
				key={1} 
				isClass="bg-gray" 
				title="Conoce nuestro" 
				subtitle="Blog" 
				list={news} 
				isLoop={true} 
				isDescriptionView={true} 
				loading={loadingPost} 
				classCarousel="services-blog"
				titleButton="Ir al blog"
			/>

			<Ubication isClass="map-link-in" isClassTitle="" />

			</Layout>
			{ 
				(isOpen && advertisingHomeMain?.length>0) && (
					advertisingHomeMain[0].is_banner_hidden===false && (
							<ModalInitial 
								isOpen={isOpen} 
								closeModal={closeModal} 
								isClass="modal-content--orange" 
								imagens={advertisingHomeMain?.length>0 ? advertisingHomeMain[0] : []}
								handleClickModal={handleClickModal}
							/>
					)
				)
			}
		</>
	)
}

export default Initial