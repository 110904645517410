import { useEffect, useRef, useState } from 'react'
import { resetInfoInitial, nextPrevForm, saveFormDataGlobal } from "services/actions/concourseAction"

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"

export const useConcourse = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const { messageConcourse, nextFormNameConcourse, dataTypeConcourse } = useSelector((state) => state.concourses)	

	const videoPhoto = useRef(null);
	const canvasPhoto = useRef(null);
	const screenshotImage = useRef(null);
	const panelTakePhotoSelected = useRef(null);
	const panelTakePhotoPreview =  useRef(null);
	const btnTakePhotoDsk = useRef(null);
	const btnTakePhotoMobile =  useRef(null);
	const [ errMsg, setErrMsg ] = useState({ status: false, message: "" });
	const [ listDevices, setListDevices ] = useState([]);
	const [ stream, setStream ] = useState(null)
	const [ showModal, setShowModal ] = useState(false)
	const [ showPoliticas, setShowPoliticas ] = useState(false)

	const gotToNextForm = (formName) => {
		dispatch(nextPrevForm(formName))
		window.scrollTo(0, 0)
	}

	const getListDevices =  (devices) => {
		const deviceVideo = [];

		// Recorrer y filtrar
		devices?.forEach(function (device) {
			const type = device.kind;
			if (type === "videoinput") {
				deviceVideo.push(device);
			}
		});
		setListDevices(deviceVideo)
		return deviceVideo
	}

	const handleTakePhoto = async () => {
		try {
			if(!tieneSoporteUserMedia()){
				setErrMsg({ status: true, message: "Parece que tu navegador no soporta esta característica. Intenta actualizarlo." })
				return false;
			}

			
			if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {

				let devices = await navigator.mediaDevices.enumerateDevices()
				let deviceVideo = getListDevices(devices)

			    const updatedConstraints = {
				      video: {
						// Justo aquí indicamos cuál dispositivo usar
						deviceId: deviceVideo.length>0 ? deviceVideo[0].deviceId : '',
					}
			    };

			    await startStream(updatedConstraints);
			}
		}catch(error){
			setErrMsg({ status: true, message: "Hubo un error con la cámara, verifique que esté activa." })
		}
	}

	const startStream = async (constraints) => {
	 	const streamMedia = await navigator.mediaDevices.getUserMedia(constraints);

	 	if(streamMedia){
	 		gotToNextForm(1)
	 		setStream(streamMedia)
			videoPhoto.current.srcObject = streamMedia;
			videoPhoto.current.play();
	 	}
	 	
	};

	const handleChangeSelected = async (event) => {
		await navigator.mediaDevices.enumerateDevices()
				
	    const updatedConstraints = {
		      video: {
				// Justo aquí indicamos cuál dispositivo usar
				deviceId: event.target.value,
			}
	    };

		if (stream) {
			stream.getTracks().forEach(function (track) {
				track.stop();
			});
		}
		// Mostrar el nuevo stream con el dispositivo seleccionado
		startStream(updatedConstraints);
	}

	function dataURLtoBlob(dataurl) {
	    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
	        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
	    while(n--){
	        u8arr[n] = bstr.charCodeAt(n);
	    }
	    return new Blob([u8arr], {type:mime});
	}

	const handleCapturePhoto = () => {
		try{
			setErrMsg({ status: false, message: "" })

		 	canvasPhoto.current.width = videoPhoto.current.videoWidth;
		 	canvasPhoto.current.height = videoPhoto.current.videoHeight;
		 	canvasPhoto.current.getContext('2d').drawImage(videoPhoto.current, 0, 0);
		 	
		 	let urlImage = canvasPhoto.current.toDataURL('image/png')
		 	if(urlImage!=="data:,"){
		 		videoPhoto.current.pause();
			 	panelTakePhotoSelected.current.classList.add('d-none')
			 	panelTakePhotoPreview.current.classList.remove('d-none')
			 	screenshotImage.current.src = urlImage
			 	dispatch(saveFormDataGlobal({ pictures: urlImage!=="" ? dataURLtoBlob(urlImage) : ""}))
			 	dispatch(saveFormDataGlobal({ typePictures: "string" }))
			 	setShowModal(true)
			}else{
				setErrMsg({ status: true, message: "Ups! Espere que la cámara se active" })
			}
		}catch(error){
			setErrMsg({ status: true, message: "Hubo un error con la cámara, verifique que esté activa." })
		}		
	};

	const handleResetCapturePhoto = () => {
		videoPhoto.current.play();
		panelTakePhotoSelected.current.classList.remove('d-none')
	 	panelTakePhotoPreview.current.classList.add('d-none')
	 	screenshotImage.current.src = null;
	}

	const handleResetPhoto = (indexForm) => {
	 	screenshotImage.current.src = null;
	 	gotToNextForm(indexForm)
	}

	const handleNextContact = (indexForm, createdPhoto) => {
		//videoPhoto.current.pause();
		//videoPhoto.current.currentTime = 0;
		gotToNextForm(3)
	}

	const handleChangeTakePhoto = (event) => {
		setErrMsg({ status: false, message: "" })

		if (!event.target.files || event.target.files.length === 0) {
			setErrMsg({ status: true, message: "Debe tomarle la foto a la factura de su auto comprado" })
            return false
		}

		gotToNextForm(2)
		setShowModal(true)
		let reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function (e) {
            let image = new Image();
            image.src = e.target.result;  
            //Validate the File Height and Width.
            image.onload = function () {
            	panelTakePhotoPreview.current.classList.remove('d-none')
            	let urlImage = URL.createObjectURL(event.target.files[0])
                screenshotImage.current.src = urlImage
                dispatch(saveFormDataGlobal({ pictures: event.target.files[0] }))
                dispatch(saveFormDataGlobal({ typePictures: "object" }))
                return true;
            };
        } 
	}

	const tieneSoporteUserMedia = ()  => {
		return !!(navigator.getUserMedia || (navigator.mozGetUserMedia || navigator.mediaDevices.getUserMedia) || navigator.webkitGetUserMedia || navigator.msGetUserMedia)
	}

	const gotToHome = () => {
		history.push('/')
        window.location.reload()
	}

	const backValidationPhoto = () => {
		if(dataTypeConcourse.pictures !== null){
			setShowModal(true)
			let reader = new FileReader();
			gotToNextForm(2)
	        reader.readAsDataURL(dataTypeConcourse.pictures);

	        reader.onload = function (e) {
	            let image = new Image();
	            image.src = e.target.result;  
	            //Validate the File Height and Width.
	            image.onload = function () {
	            	panelTakePhotoPreview.current.classList.remove('d-none')
	            	let urlImage = URL.createObjectURL(dataTypeConcourse.pictures)
	                screenshotImage.current.src = urlImage
	                return true;
	            };
	        } 
		}
	}

	const handleCloseModal = () => {
		setShowModal(!showModal)
	}

	useEffect(() => {
		dispatch(resetInfoInitial("form_initial"))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleModalPoliticas = (event) => {
		event.preventDefault()
		setShowPoliticas(!showPoliticas)
	}

	return {
		
		nextFormNameConcourse,
		handleTakePhoto,
		handleChangeTakePhoto,
		btnTakePhotoDsk, 
		btnTakePhotoMobile,
		videoPhoto, 
		canvasPhoto, 
		screenshotImage,
		handleCapturePhoto,
		listDevices,
		panelTakePhotoSelected, 
		panelTakePhotoPreview,
		handleResetPhoto,
		handleNextContact,
		handleResetCapturePhoto,
		handleChangeSelected,
		errMsg,
		messageConcourse,
		gotToHome,
		backValidationPhoto,
		showModal,
		handleCloseModal,
		handleModalPoliticas,
		showPoliticas
	}
}