 
  export const validateOnlyLetter = (event) => {
    // Verifica si la tecla presionada es una letra, espacio o las teclas de borrado
    if (!/^[ñíóáéúa-zA-ZÑÍÓÁÉÚ\s]$/.test(event.key)) {
      // Previene la acción predeterminada del evento
      event.preventDefault();
    }
  }

  export const validateOnlyNumber= (event) => {
    const keyCode = event.keyCode || event.which;
    // Permitir solo números y backspace
    const expres = /[0-9]/;
    if (!(expres.test(event.key) || keyCode === 8)) {
        event.preventDefault();
    }
  }

  export const handlePasteOnlyLetter = (event) => {
    // Obtiene el texto pegado desde el portapapeles
    const pastedText = event.clipboardData.getData("text");
  
    // Verifica si el texto pegado contiene solo letras y espacios
    if (!/^[ñíóáéúa-zA-ZÑÍÓÁÉÚ\s]*$/.test(pastedText)) {
      // Evita que se pegue el texto no válido
      event.preventDefault();
    }
  };
  
  export const handlePasteOnlyNumber = (event) => {
    // Obtiene el texto pegado desde el portapapeles
    const pastedText = event.clipboardData.getData("text");
  
    // Verifica si el texto pegado contiene solo letras y espacios
    if (!/^[0-9\s]*$/.test(pastedText)) {
      // Evita que se pegue el texto no válido
      event.preventDefault();
    }
  };
  