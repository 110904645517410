import API from 'services/context/api';
import { 
	LIST_INITIAL_SERVICE,
	RESULT_SERVICES_SEARCH,
	LOADING_SEARCH_SERVICE,

	SELECTED_SERVICE,
	LIST_INITIAL_TIRE,
	FILTER_MEASURE_TIRES,
	LOADING_RESULT_TIRES,
	SELECTED_TIRE_QUOTE,
	SEND_MEASURE_TIRES,

	SELECTED_VALUE_FORM,
	SELECT_ITEM_LIST_FORM,

} from 'services/types/serviceType';

import { listWidth, listProfile, listRing, listTires, listBrandTire } from 'utils/TireTest';
import { listTireByMeasure } from 'data/TiresValue.json';
import { formatListStore } from 'utils/StoreFunction/toolStore';
import { data_ferripaldi } from 'utils/dataBasicFija';
import { TOKEN_PROVIDER } from "data/variableKey";

import endPoints from 'services/routes/';

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getListInitialServices = () => async (dispatch, getState) => {
	try{
	  	
		let response = await API.getApi({
								endPoint: endPoints.store.categoryProviders, 
								nameAPI: TOKEN_PROVIDER, 
								isToken: true,
								isFormData: false
							});
		
		let { data, success, message } = response.data;

		if(success){
			let dataOriginalServices =JSON.parse(JSON.stringify(data))
			let servicesHomeOriginal = data.filter(x=> x.name!=='Contactar' && x.name!=='Gimnasio');
			
			const listHeaderH1 = [
				{
					slug: "baterias",
					title: "Baterías para Autos"
				},
				{
					slug: "sistema-de-frenos",
					title: "Servicio para Sistema de Frenos"
				},
				{
					slug: "cambio-de-aceite",
					title: "Cambio de Aceite y Filtro de Motor"
				},
				{
					slug: "alineacion-y-balanceo",
					title: "Alineación y Balanceo de Ruedas"
				},
				{
					slug: "llantas-autos",
					title: "Llantas para Autos"
				},
				{
					slug: "amortiguadores",
					title: "Venta y Cambio de Amortiguadores "
				}
			];
		
			servicesHomeOriginal = servicesHomeOriginal.map(item => {
				/**item.url = `/servicio-automotriz/${item.slug}`;
				if(item.name==='Autos Nuevos'){
					item.url = '/autos/nuevos';
					item.name='Nuevos';
				}
				if(item.name==='Autos Usados'){
					item.url = '/autos/usados';
					item.name='Usados';
				}
				if(item.name==="Neumáticos"){
					item.url = '/servicio-automotriz/neumaticos';
				}
				if(item.name==="Motos"){
					item.url = '/motos';
				}
				if(item.name==="Scooter y Bicicleta"){
					item.url = '/scooter-y-bicicletas-electricas';
				}**/
				const headerH1 = listHeaderH1.find(header => header.slug === item.slug);
				
				item.titleH1 = (headerH1) ? headerH1.title : item.name;

				return item;
			});
	
			let servicesHome = servicesHomeOriginal.filter(item => item.show_in_home===true)
			let viewPageService = servicesHomeOriginal.filter(item => item.show_in_services===true)

			let homeAll = servicesHome.filter(x=> x.name!=='Pista de prueba' && x.name!=='Patio de comidas' && x.name!=='Gimnasio');

			const services = servicesHome.filter(x=> x.name!=='Nuevos' && x.name!=='Usados');
	
			const serviceMain = services.filter(x=> x.name!=='Pista de prueba' && x.name!=='Patio de comidas' && x.name!=='Gimnasio');
			const serviceOther = services.filter(x=> x.name==='Pista de prueba' || x.name==='Patio de comidas');
			const servicesList = { main: serviceMain, secondary: serviceOther	};

			const orderServices = [
				{
					slug: "autorizados-de-marca",
					order: 1,					
					isNew: true
				},
				{
					slug: "cambio-de-aceite",
					order: 2,
					isNew: false
				},
				{
					slug: "accesorios",
					order: 3,
					isNew: false
				},
				{
					slug: "amortiguadores",
					order: 4,
					isNew: false
				},
				{
					slug: "alineacion-y-balanceo",
					order: 5,
					isNew: false
				},
				{
					slug: "autopistas",
					order: 6,
					isNew: false
				},
				{
					slug: "baterias",
					order: 7,
					isNew: false
				},
				{
					slug: "vende-tu-auto",
					order: 8,
					isNew: true
				},
				{
					slug: "financiamiento",
					order: 9,
					isNew: false
				},
				{
					slug: "sistema-de-frenos",
					order: 10,
					isNew: false
				},
				{
					slug: "repuestos",
					order: 11,
					isNew: false
				},
				{
					slug: "revision-de-kilometraje",
					order: 12,
					isNew: false
				},
				{
					slug: "llantas-autos",
					order: 13,
					isNew: false
				},
				{
					slug: "motorhome-y-caravanas",
					order: 14,
					isNew: false
				},
				{
					slug: "seguros",
					order: 15,
					isNew: true
				}
			]

			
			let serviceOne = [];
			let serviceTwo = [];
			for(let index in viewPageService){
				const findService = orderServices.find(ser => ser.slug===viewPageService[index].slug);
				if(findService){
					serviceOne = [
						...serviceOne, 
						{
							...viewPageService[index],
							...findService
						}
					]
				}else{
					serviceTwo = [...serviceTwo, viewPageService[index]]
				}
			}
			const spliceService = [...serviceOne, ...serviceTwo];
			spliceService.sort(((a, b) => a.order - b.order))
		
			const count = spliceService.length/2;
			const listMenuServicesAll = {
				listOne: spliceService.slice(0,count+1),
				listTwo: spliceService.slice(count+1)
			}  
			
			const payload = { 
				servicesAll: services, 
				listServices: servicesList, 
				home: homeAll, 
				categoryProviders: dataOriginalServices,
				viewPageService: spliceService,
				listMenuServicesAll
			};
		
			dispatch(requestSuccess(LIST_INITIAL_SERVICE, payload));
		}

		return { status: success, message };
		
    }catch(error){
      return {status: false, message:error};
    }
};

export const searchServiceBySlug = (slug) => async (dispatch, getState) => {
	try{

		const { servicesAll, categoryProviders } = getState().services;

		dispatch(requestSuccess(LOADING_SEARCH_SERVICE, true));

		let urlService = "";

		let response = await API.getApi({
									endPoint: endPoints.store.providerServicesBySlug(slug), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								})

		let { data, success, message } = response.data;
		
		if(success){
			const service = servicesAll.find(x=> x.slug===slug) ?? {};

			data.sort((a, b) => {
			    if(a.name > b.name) return 1;
			    if(a.name < b.name) return -1;

			    return 0;
			});
		
			const listServices = data.map(item => {
				let store = formatListStore(item, categoryProviders);
				store.list = []
				store.isData = false
				return store;
			});
			
			const payload = { isLoading: false, list: listServices, visible: true, service };
			urlService = service?.url;
		
			dispatch(requestSuccess(RESULT_SERVICES_SEARCH, payload));
		}else{
			dispatch(requestSuccess(LOADING_SEARCH_SERVICE, false));
		}
		
		return { status: success, message, url: urlService };

    }catch(error){
		
    	dispatch(requestSuccess(LOADING_SEARCH_SERVICE, false));
      	return { status: false, message: error };
    }
};

export const getSelectedService = (slug) => (dispatch, getState) => {
	try{
		
		const { resultServices } = getState().services;

		if(resultServices.list.length>0){

			const siteService = resultServices.list.find(x=> x.slug===slug);

			if(siteService){
				const payload = { ...siteService, category: 'Servicios' };

				dispatch(requestSuccess(SELECTED_SERVICE, payload));
			}
		}
    }catch(error){
      return {status: false, message:error};
    }
};

export const getSelectedItemService = (id) => (dispatch, getState) => {
	try{
		
		const { serviceSelected } = getState().services;
		
		if(Object.keys(serviceSelected).length>0){

			const item = serviceSelected.list.find(x=> x.id===id);

			if(item){
				const payload = { itemSelectedListForm: item };
		
				dispatch(requestSuccess(SELECT_ITEM_LIST_FORM, payload));
			}
		}
    }catch(error){
	
      return {status: false, message:error};
    }
};

export const getListInitialTires = () => (dispatch, getState) => {
	try{

		const payload = { 
			listTires: listTires, 
			isViewTires: true,
			measures: { 
				listWidth: orderList(listWidth), 
				listProfile: orderList(listProfile), 
				listRing: orderList(listRing) 
			}
		};
		dispatch(requestSuccess(LIST_INITIAL_TIRE, payload));
    }catch(error){
      return {status: false, message:error};
    }
};

export const searchTireByMeasure = (body) => (dispatch, getState) => {
	try{
		dispatch(requestSuccess(LOADING_RESULT_TIRES, true));

		let filterMeasure = listTireByMeasure;

		if(body.width!==""){
			filterMeasure = filterMeasure.filter(item => item.width===Number(body.width));
		}

		if(body.profile!==""){
			if(body.profile==="R"){
				filterMeasure = filterMeasure.filter(item => item.profile===body.profile);
			}else{
				filterMeasure = filterMeasure.filter(item => item.profile===Number(body.profile));
			}			
		}

		if(body.ring!==""){
			filterMeasure = filterMeasure.filter(item => item.ring===Number(body.ring));
		}

		if(typeof body?.brand!=="undefined" && body?.brand!==""){
			filterMeasure = filterMeasure.filter(item => item.concessionaire.toLowerCase()===body?.brand?.toLowerCase());
		}

		const listResult = filterMeasure.map(item => {
			const photo = listBrandTire.find(brand => brand.title.toLowerCase()===item.brand.toLowerCase());
			return {
				...item,
				brand: item.brand.toUpperCase(),
				model: item.model.toUpperCase(),
				photo,
				provider: item.concessionaire
			}
		});
		
		const payload = { 
			listResultTires: listResult.sort((a, b) => {
                if(b.description < a.description) return 1;
                if(b.description > a.description) return -1;

                return 0;
            }),
			isLoading: false,
			visible: true
		};

		setTimeout(function(){
			dispatch(requestSuccess(FILTER_MEASURE_TIRES, payload));
		},3000)
		
    }catch(error){
      return {status: false, message:error};
    }
} ;

const orderList = (listData) => {
	return listData.sort((elemA, elemB)=> {
		return elemA - elemB;
	});
};

export const selectedItemQuote = (item) => (dispatch, getState) => {
	try{

		const { tireSelectedQuote } = getState().services;
		let payload = [];

		const isFind = tireSelectedQuote.find(tire => tire.id===item.id);
		if(!isFind){
			const information = { ...item, titleOption: `${item.brand} ${item.model}`};
			payload = [...tireSelectedQuote, information];
			
		}else{
			payload = tireSelectedQuote.filter(tire => tire.id!==item.id);
		}

		dispatch(requestSuccess(SELECTED_TIRE_QUOTE, payload));
    }catch(error){
      return {status: false, message:error};
    }
};
export const loadingFormTire = () => (dispatch, getState) => {
	dispatch(requestSuccess(LOADING_RESULT_TIRES, true));
}

export const sendFormService = () => (dispatch, getState) => {
	try{
		const { resultTires } = getState().services;

		const payload = { 
			tires: { isLoading: false, list: resultTires?.list, visible: false },
			tireSelectedQuote: []
		};

		dispatch(requestSuccess(SEND_MEASURE_TIRES, payload));
		
		return { status: true, message: "" };

    }catch(error){
		dispatch(requestSuccess(LOADING_RESULT_TIRES, false));
      	return { status: false, message: error };
    }
};

export const getTireByStore = (slug) => (dispatch, getState) => {
	try{
		const { detailConcessionare } = getState().concessionaire;

		dispatch(requestSuccess(FILTER_MEASURE_TIRES, { isLoading: true, listResultTires: [], visible: true }));

		let filterMeasure = listTireByMeasure.filter(item => item.concessionaire.toLowerCase()===slug.toLowerCase());

		const listResult = filterMeasure.map(item => {
			const photo = listBrandTire.find(brand => brand.title.toLowerCase()===item.brand.toLowerCase());
			return {
				...item,
				photo,
				provider: detailConcessionare?.name
			};
		});

		const payload = { 
			listResultTires: listResult,
			isLoading: false,
			visible: false
		};
	
		setTimeout(function(){
			dispatch(requestSuccess(FILTER_MEASURE_TIRES, payload));
		},3000)
		
    }catch(error){
      return {status: false, message:error};
    }
};

export const selectedValueForm = (data) => (dispatch, getState) => {
	localStorage.setItem('valueTire', JSON.stringify(data))
	dispatch(requestSuccess(SELECTED_VALUE_FORM, data));
};

export const getServicesProviderByID = (slug) => async (dispatch, getState) => {
	try{
		const { categoryProviders } = getState().services;

		let resultProvider = {};

		let response = await API.getApi({
									endPoint: endPoints.store.providerBySlug(slug), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});

		let { data, success, message } = response.data;
		
		if(data!==null){
	
			let localMap = formatListStore(data, categoryProviders);
	
			resultProvider = {
				...localMap,
				id: data.id,
				type: data.type,
				category: 'Servicios', 
				isData: true,
				categoryNTitle: 'Servicios',
				categoryName: 'Servicios'
			};


			let result = await API.getApi({
								endPoint: endPoints.store.getServicesByProviderId(data?.id), 
								nameAPI: TOKEN_PROVIDER, 
								isToken: true,
								isFormData: false
							});
			let { data: dataService, success: successService } = result.data;
			
			if(successService){
				let serviceList = [];
			
				if(resultProvider.name.toLowerCase() === "ferripaldi"){
					serviceList = data_ferripaldi.map(item => {
						return {
							...item,
							localIdMap: resultProvider.locals,
							name: "",
							description: "",
							categoryProviderId: resultProvider.type,
							listFeatures: [
								{title: 'Vende', value: resultProvider.name }
							]
						}
					});
				}else{
					serviceList = dataService.filter(item => item?.name!=="Autos Nuevos" && item?.name!=="Autos Usados").map(item => {
						return {
							...item,
							concessionaire: resultProvider?.name,
							photoMain:"/static/media/f-repuestos.c898c970.png",
							status_id:1,
							localIdMap: resultProvider.locals,
							categoryProviderId: resultProvider.id,
							description: item?.name,
							listFeatures: [
								{title: 'Vende', value: resultProvider.name }
							]
						}
					});
				}
				resultProvider = {
					...resultProvider,
					list: serviceList
				}
			}

			const payload = {
				service: resultProvider,
				loading: false,
				isDataService: 1
			}
		
			dispatch(requestSuccess(SELECTED_SERVICE, payload));

		}else{
			const payload = {
				service: {},
				loading: true,
				isDataService: 0
			};
			dispatch(requestSuccess(SELECTED_SERVICE, payload));
		}
		
		return { status: success, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

export const getServicesByAutorized = (slug) => async (dispatch, getState) => {
	try{
		const { categoryProviders } = getState().services;

		let resultProvider = {};

		let response = await API.getApi({
									endPoint: endPoints.store.providerBySlug(slug), 
									nameAPI: TOKEN_PROVIDER, 
									isToken: true,
									isFormData: false
								});
							
		let { data, success, message } = response.data;
		
		if(data!==null){
	
			let localMap = formatListStore(data, categoryProviders);
	
			resultProvider = {
				...localMap,
				id: data.id,
				type: data.type,
				category: 'Servicios', 
				isData: true,
				categoryNTitle: 'Servicios',
				categoryName: 'Servicios'
			};


			let result = await API.getApi({
								endPoint: endPoints.store.getServicesByProviderId(data?.id), 
								nameAPI: TOKEN_PROVIDER, 
								isToken: true,
								isFormData: false
							});
							
			let { data: dataService, success: successService } = result.data;
	
			if(successService){
				let serviceList = dataService.map(item => {
					return {
						...item,
						concessionaire: resultProvider?.name,
						//photoMain:"/static/media/f-repuestos.c898c970.png",
						//status_id:1,
						localIdMap: resultProvider.locals,
						categoryProviderId: resultProvider.id,
						description: item?.name,
						listFeatures: [
							{title: 'Vende', value: resultProvider.name }
						]
					}
				});
			
				resultProvider = {
					...resultProvider,
					list: serviceList
				}
			}

			const payload = {
				service: resultProvider,
				loading: false,
				isDataService: 1
			}
		
			dispatch(requestSuccess(SELECTED_SERVICE, payload));

		}else{
			const payload = {
				service: {},
				loading: true,
				isDataService: 0
			};
			dispatch(requestSuccess(SELECTED_SERVICE, payload));
		}
		
		return { status: success, message };

    }catch(error){
      	return { status: false, message: error };
    }
};

//RESET DATA SERVICES SELECTED
export const resetServiceSelected = () => (dispatch, getState) => {
	const payload = {
		service: {},
		loading: true,
		isDataService: 1
	};
	dispatch(requestSuccess(SELECTED_SERVICE, payload));
};