import { useState, useEffect } from 'react'

import Header from 'components/Layout/Header'
import Footer from 'components/Layout/Footer'
import MetaHead from 'components/Layout/MetaHead'

import ModalSoap from 'components/QuoteCar/Soap/ModalSoap'
import ModalSire from 'components/QuoteCar/Sire/ModalSire'
import ModalCar from 'components/QuoteCar/Cars/ModalCar'

import { useLocation } from 'react-router-dom'
import { useAdvertising } from 'hooks/useAdvertising'
import { useSelector, useDispatch } from 'react-redux'
import { useSoap } from "hooks/Quotes/Soap/useSoap"
import { useSire } from "hooks/Quotes/Sire/useSire"
import { useOlxMain } from "hooks/Quotes/Car/useOlxMain"
import { useFunctionBaseConcesionaire } from "hooks/Concesionaire/useFunctionBaseConcesionaire";
import { getListInitialServices } from 'services/actions/serviceActions'


const Layout = ({classHeader, hiddenColor, isVisibleFooter, children, visibleLine, infoMetaHead, classDefaultHeader, classTopHeader,
	classMenuHeader, typePage }) => {
	
	const dispatch = useDispatch()
	const location = useLocation()
	const { closeModalSoap, sendLinkSoap } = useSoap(false)
	const { isModalSoap } = useSelector((state) => state.sureSoap)
	const { closeModalCompara } = useSire(false)
	const { isModalCompara } = useSelector((state) => state.quotes_car)
	const { isModalOLX } = useSelector((state) => state.sureOlx)
	const { closeModalCarOlx } = useOlxMain()
	const { actionAdvertising } = useAdvertising()
	const { actions: { getParamsUrl } } = useFunctionBaseConcesionaire()
	const {  listMenuServicesAll, categoryProviders } = useSelector((state) => state.services)

	const [ navbarClass, setNavbarClass ] = useState(false)

	const changeBackgroundNavbar = () => {
		if(window.scrollY>0){
			setNavbarClass(navbarClass => true)
		}else{
			setNavbarClass(navbarClass => false)
		}
	}

	const getLoadListProvider = async () => {
	
		if(categoryProviders.length===0){
			await dispatch(getListInitialServices())
		}		
	}

	useEffect(() => {
	  window.scrollTo(0, 0)
	  getParamsUrl(location?.search);
	  getLoadListProvider()
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	

	window.addEventListener("scroll", changeBackgroundNavbar)

	return (
		<>	
			<MetaHead {...infoMetaHead} />
			<header className={`${classDefaultHeader} ${navbarClass===true ? classTopHeader : classMenuHeader }`}>
				<div className="col-12">
					<Header 
						hiddenColor={hiddenColor} 
						actionAdvertising={actionAdvertising} 
						navbarClass={typePage ? navbarClass : true} 
						listMenuServicesAll={listMenuServicesAll}
					/>
					{visibleLine && <hr className="line__menu" />}
				</div>
			</header>
			{classHeader!=="" &&  <div className={classHeader}></div>} 
			{children}
			{isVisibleFooter && <Footer actionAdvertising={actionAdvertising} listMenuServicesAll={listMenuServicesAll} />}

			{isModalSoap && (
                <ModalSoap
                    visible={isModalSoap} 
                    closeModal={closeModalSoap} 
                    sendLinkSoap={sendLinkSoap}
                />
            )}

            {isModalCompara && (
                <ModalSire
                    visible={isModalCompara} 
                    closeModal={closeModalCompara} 
                />
            )}

            {isModalOLX && (
                <ModalCar 
                    visible={isModalOLX} 
                    closedModalOLX={closeModalCarOlx} 
                />
			)}
			<a
				href="https://wa.me/+56968467814?text=Hola%20Movicenter%20%F0%9F%9A%97"
				target="_blank"
				rel="noopener noreferrer"
				style={{position: "fixed", bottom: "40px", right: "40px", backgroundColor: "#25d366", color: "#fff", borderRadius: "50px", textAlign: "center", fontSize: "30px", boxShadow: "2px 2px 3px #999", width: "45px", height: "45px", marginBottom: "1.25rem", zIndex: 50}}
				>
				<svg
				aria-hidden="true"
				focusable="false"
				style={{display: "inline-block", height: "1em", overflow: "visible", verticalAlign: "-0.125em", margiTtop: "8px"}}
				role="img"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 448 512"
				>
				<path
					fill="#ffffff"
					d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
					/>
				</svg>
			</a>
		</>
	)
}

export default Layout

Layout.defaultProps = {
	classHeader: "",
	//isVisibleMain: false,
	hiddenColor: true,
	isVisibleFooter: true,
	visibleLine: false
}