import { useState } from "react"
//import arrows from "assets/images/concourse/arrows.svg"
import logo_white from "assets/images/logo.png"
import styles from 'styles/concourse.module.css'
import LoadingUserScreen from 'components/Layout/LoadingUserScreen';

const PanelCardLogo = ({  nextFormNameConcourse, title, type, subtitle, gitVehicle, 
    classGitVehicle, classSubtitle, classTitle, handleModalPoliticas, gitLogoMain, children }) => {

    const [loadingPhoto, setLoadingPhoto] = useState(true);

    const verifiedLoadPhoto = () => {
        setLoadingPhoto(false);
    };

    return (
        <>
            {
                loadingPhoto && (
                <LoadingUserScreen isLoading={loadingPhoto} />
                )
            }
            <div className={`row ${styles["background-moto"]}`}>
                <img 
                    src="https://movicenter-web.s3.us-east-2.amazonaws.com/assets/concourse-bencina-mob.png" 
                    alt="Concurso bencina"
                    className={styles["background-moto__photo"]} 
                    priority={true}
                    loading="eager"
                    onLoad={verifiedLoadPhoto}
                />
                <img 
                    src="https://movicenter-web.s3.us-east-2.amazonaws.com/assets/concourse-bencina-dsk.png" 
                    alt="Concurso bencina" 
                    className={styles["background-moto__photodsk"]} 
                    priority={true}
                    loading="eager"
                    onLoad={verifiedLoadPhoto}
                />
                {
                    !loadingPhoto && ( 
                        <>
                            <div className={styles["background-moto__absolute"]} />
                            <div className={`col-12`}>
                                <div className={`container ${styles["container"]}`}>
                                    <section className={`${styles["main"]} `}>
                                        <div className={styles["main-container"]}>
                                            <div className={styles["container-logos"]}>
                                                <div className={styles["logo-title"]}>
                                                    <img src={"https://movicenter-web.s3.us-east-2.amazonaws.com/assets/logo-movidays.png"} alt={type} />
                                                </div>
                                                <div className={styles[classGitVehicle]}>
                                                    <img src={"https://movicenter-web.s3.us-east-2.amazonaws.com/assets/logo-bencina.png"} alt={type} />
                                                </div>
                                            </div>
                                            {nextFormNameConcourse!=="form_end" && (
                                                <div className={styles["join-container"]}>
                                                    <h3 className={styles["join-title"]}>¿Cómo concursar?</h3>
                                                </div>
                                            )}
                                            {children}
                                            <div className={styles["footer"]}>
                                                <img src={logo_white} alt="Logo" className="w-100" />
                                            </div>
                                            {nextFormNameConcourse==="form_initial" && (
                                                <p className={`${styles["text-base"]}`}>(*) Participan sólo compras de autos nuevos, realizadas en Movicenter durante el mes de Agosto 2024.
                                                <a href="!#" className={`${styles["bases-link"]} ml-1 ${styles["text-underline"]}`}
                                                    onClick={(event) => handleModalPoliticas(event)}
                                                    >Revisa las bases aquí</a>
                                                </p>                      
                                            )}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </>
                    )
                }               
            </div>
        </>
    )
}

export default PanelCardLogo