import { Controller } from "react-hook-form"
import { ProgressBar } from "react-bootstrap"
//import icon_1 from "assets/images/concourse/1.svg"
//import icon_2 from "assets/images/concourse/2.svg"
import styles from 'styles/concourse.module.css'
import { useFormContactCar } from "hooks/Concourse/useFormContactCar"
import MaskedInput from 'react-text-mask'
import RutTextMask from 'rut-text-mask'
import { validateOnlyLetter, validateOnlyNumber, handlePasteOnlyLetter, handlePasteOnlyNumber } from "utils/validationForm"

const FormContactCar = ({ gotToNextForm, info_1 }) => {

	const { register, onSubmit, handleSubmit, control, errors, handleBlurRut, 
        onChangeInput, handleChangePhoneNumber,	handleChangeEmail, dataTypeConcourse,
        proccessForm, handleActive, count, validatePhone, validateMail } = useFormContactCar()

	return (
        <>
            <div className={styles["list-item"]}>
                <div className={styles["item"]}>
                    <div className={styles["item-image"]}>
						<p>1</p>
					</div>
                    <p className={styles["item-description"]}>{info_1}</p>
                </div>
                <div className={styles["item"]}>
                    <div className={styles["item-image"]}>
						<p>2</p>
					</div>
                    <p className={styles["item-description"]}>Completa el formulario con tus datos.</p>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group quote-contact__form mt-5">
                    <label htmlFor="rut" className="quote-contact__label">Rut</label>
                    <Controller
                        render={(props) => (
                            <MaskedInput
                                mask={RutTextMask}
                                className="form-control quote-contact__input" 
                                placeholder="Ej: 12.345.678-9"
                                id="rut" 
                                name="rut"
                                value={props.value}
                                onChange={(value)=>{
                                    props.onChange(value)
                                    onChangeInput(value, "text", "rut")
                                }}
                                onBlur={(event) => handleBlurRut(event)}
                            />
                        )}
                        control={control}
                        name="rut"  
                        defaultValue={dataTypeConcourse?.rut}
                        rules={{required: {value: true, message: 'Requerido'}}}
                    />
                    {errors.rut && (
                        <p className="text-error text-white">
                            {errors?.rut?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="name" className="quote-contact__label">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control quote-contact__input"  
                        id="name" 
                        name="name"
                        ref={register({
                            required: {value: true, message: 'Requerido'},
                            minLength: {value: 3, message: 'Mínimo 3 carácteres'},
                            pattern: { value: /^[ñíóáéúa-zA-ZÑÍÓÁÉÚ\s]+$/i, message: 'Solo se permite letras'}
                        })}
                        onChange={(event)=>onChangeInput(event, "text", "name")}
                        maxLength={20}
                        onKeyPress={(event) => validateOnlyLetter(event)}
                        onPaste={(event) => handlePasteOnlyLetter(event)}
                    />
                    {errors.name && (
                        <p className="text-error text-white">
                            {errors?.name?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="surname" className="quote-contact__label">Apellido</label>
                    <input 
                        type="text" 
                        className="form-control quote-contact__input"  
                        id="surname" 
                        name="surname"
                        ref={register(
                            {
                                required: {value: true, message: 'Requerido'},
                                minLength: {value: 3, message: 'Mínimo 3 carácteres'},
                                pattern: { value: /^[ñíóáéúa-zA-ZÑÍÓÁÉÚ\s]+$/i, message: 'Solo se permite letras'}
                        })}
                         onChange={(event)=>onChangeInput(event, "text", "surname")}
                        maxLength={20}
                        onKeyPress={(event) => validateOnlyLetter(event)}
                        onPaste={(event) => handlePasteOnlyLetter(event)}
                    />
                    {errors.surname && (
                        <p className="text-error text-white">
                            {errors?.surname?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="phone" className="quote-contact__label">Teléfono</label>
                    <p className="span-telephone"> +56</p>
                    <input 
                            type="text" 
                            className="form-control quote-contact__input telephone--left"  
                            id="phone" 
                            name="phone"
                            ref={register({
                                minLength: { value: 9, message: "Mínimo 9 dígitos" },
                                required: { value: true, message: "Requerido" },
                                //eslint-disable-next-line
                                pattern: {
                                  value: /^([0-9]+)$/,
                                  message: "Teléfono inválido",
                                },
                                validate: validatePhone,
                              })}
                            maxLength={9}
                            pattern="[0-9]+"
                            onChange={(event)=>handleChangePhoneNumber(event)}
                            onBlur={(event) => onChangeInput(event, "text", "phone")}
                            onKeyPress={(event) => validateOnlyNumber(event)}
                            onPaste={(event) => handlePasteOnlyNumber(event)}
                        />
                    {errors.phone && (
                        <p className="text-error text-white">
                            {errors?.phone?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="email" className="quote-contact__label">E-mail</label>
                    <input 
                        type="text" 
                        className={`form-control quote-contact__input ${styles["text-lowercase"]}`}  
                        id="email" 
                        name="email"
                        ref={register({
                            required: {value: true, message: 'Requerido'},
                            minLength: { value: 3, message: "Mínimo 3 carácteres" },
                            //eslint-disable-next-line
                            pattern: { value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message: 'El e-mail es inválido'},
                            validate: validateMail
                        })}

                        onBlur={(event)=>handleChangeEmail(event)}
                        onChange={(event) => onChangeInput(event, "text", "email")}
                    />
                    {errors.email && (
                        <p className="text-error text-white">
                            {errors?.email?.message}
                        </p>
                    )}
                </div>
                <div className="form-group quote-contact__form">
                    <label htmlFor="invoice_number" className="quote-contact__label">Número de factura</label>
                    <input 
                        type="text" 
                        className="form-control quote-contact__input"  
                        id="invoice_number" 
                        name="invoice_number"
                        ref={register({
                            minLength: { value: 3, message: "Mínimo 3 dígitos" },
                            required: { value: true, message: "Requerido" },
                            //eslint-disable-next-line
                            pattern: {
                                value: /^([0-9]+)$/,
                                message: "Número de factura inválida",
                            },
                        })}
                        onChange={(event)=>onChangeInput(event, "text", "invoice_number")}
                        maxLength={10}
                        onKeyPress={(event) => validateOnlyNumber(event)}
                        onPaste={(event) => handlePasteOnlyNumber(event)}
                    />
                    {errors.invoice_number && (
                        <p className="text-error text-white">
                            {errors?.invoice_number?.message}
                        </p>
                    )}
                </div>
                <div className={`form-group ${styles["form-group__concourse"]}`}>
                    <input 
                        className={styles["form-concourse__check"]} 
                        type="checkbox" 
                        value={true}
                        id="acceptTerm" 
                        name="acceptTerm"
                        ref={register}
                        defaultChecked={false}
                        onChange={(event)=>handleActive(event)}
                     />
                     <label 
                        className={styles["form-concourse__label"]}  
                        htmlFor="acceptTerm">
                        Acepto los <a 
                            href="https://movicenter-web.s3.us-east-2.amazonaws.com/docs/TEERMINOS+Y+CONDICIONES+MOVICENTER.pdf" 
                            target="_blank"
                            rel="noreferrer"
                        >términos y condiciones</a> y las políticas de privacidad
                    </label>
                </div>
                {proccessForm?.loading && (
                    <div className="mt-5">
                        <ProgressBar animated now={count} striped variant="success" />
                        <p className={styles["form-concourse__label"]}>
                            Se está procesando tu foto, espere unos minutos...
                        </p>
                    </div>
                )}
                
                {proccessForm.message!=="" && (
                    <div className={`${styles["error-container"]} mt-5`}>
                        <p className={styles["error-container__description"]}>{proccessForm.message}</p>
                    </div>
                )}
                <button 
                    type="submit" 
                    className={`btn btn-primary ${styles["btn-next"]}`}
                    disabled={proccessForm?.status}
                >
                { proccessForm?.loading ? "Procesando..." : "¡Concursar!" } 
                </button>
                <p className={styles["back-contact"]} onClick={()=>gotToNextForm()}>Volver</p>
            </form>
        </>
	)
}

export default FormContactCar